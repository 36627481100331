import { Injectable, Optional } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { environment } from '@environments/environment';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';

// import { SignalRService } from '@services/signal-r.service';
import { first } from 'rxjs/operators';
import { User } from 'oidc-client';

@Injectable()
export class IdleService {
  private interval: NodeJS.Timeout | nil;
  private pollingInterval: number = 0;
  private pollRunning: boolean = false;
  private timeoutTriggered: boolean = false;
  private logoutTriggered: boolean = false;
  private readonly localStorageIdleStartTimeItem: string = 'idleStartTime';
  private readonly subscriptions: Subscription = new Subscription();
  private readonly timeoutCounter: Subject<void> = new Subject<void>();
  private readonly timeoutReset: Subject<void> = new Subject<void>();
  private readonly pollStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly pollStop: Subject<void> = new Subject<void>();

  public readonly timeoutCounter$: Observable<void> = this.timeoutCounter.asObservable();
  public readonly timeoutReset$: Observable<void> = this.timeoutReset.asObservable();
  public readonly pollStart$: Observable<boolean> = this.pollStart.asObservable();
  public readonly pollStop$: Observable<void> = this.pollStop.asObservable();

  constructor(private authService: AuthService) {
    if (window.frameElement == null) {
      this.pollingInterval = environment.Idle.pollingIntervalMS;
      this.resetTime();
      this.subscriptions.add(
        this.authService.userLoaded$
          .pipe(
            first((result: User | nil) => {
              if (result) {
                return true;
              }
              return false;
            }),
          )
          .subscribe((s) => this.startPoll()),
      );
      this.subscriptions.add(this.authService.userLogout$.subscribe((s) => this.stopPoll()));
    }
  }

  ngOnDestroy() {
    if (window.frameElement == null) {
      this.subscriptions.unsubscribe();
      this.stopPoll();
    }
  }

  public get currentSecondsToLogout(): number {
    let currentTime: number = new Date().getTime();
    let msToLogout: number = this.logoutMS - currentTime;

    if (msToLogout < 1000) {
      return 0;
    }

    return Math.floor(msToLogout / 1000);
  }

  public resetPollCounter() {
    this.resetTime();
    this.resetTriggerFlags();
    this.triggerTimeoutReset();
  }

  private startPoll() {
    if (!this.pollRunning) {
      this.pollRunning = true;
      this.resetPollCounter();

      this.interval = setInterval(() => {
        this.poll.apply(this);
      }, this.pollingInterval);

      this.triggerPollStart();
    }
  }

  private stopPoll() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.triggerPollStop();
    this.pollRunning = false;
  }

  private resetTime() {
    localStorage.setItem(this.localStorageIdleStartTimeItem, new Date().getTime().toString());
  }

  private resetTriggerFlags() {
    this.timeoutTriggered = this.logoutTriggered = false;
  }

  private get timeoutMS(): number {
    return this.idleStartTime + environment.Idle.msUntilTimeoutCounter;
  }

  private get logoutMS(): number {
    return this.timeoutMS + environment.Idle.msUntilLogoutFromTimeoutCounter;
  }

  private get idleStartTime(): number {
    const item: string | nil = localStorage.getItem(this.localStorageIdleStartTimeItem);
    return item ? parseInt(item) : 0;
  }

  private poll() {
    let currentMS: number = new Date().getTime();

    if (!this.timeoutTriggered && currentMS >= this.timeoutMS) {
      this.triggerTimeout();
    } else if (this.timeoutTriggered && currentMS < this.timeoutMS) {
      // We need this block in case another tab reset it.
      this.timeoutTriggered = false;
      this.triggerTimeoutReset();
    }

    if (!this.logoutTriggered && currentMS >= this.logoutMS) {
      // this.signalR.disconnect();
      this.authService.logOut(true);
    }
  }

  private triggerPollStart() {
    this.pollStart.next(true);
  }

  private triggerPollStop() {
    this.pollStop.next();
  }

  private triggerTimeout() {
    this.timeoutTriggered = true;
    this.timeoutCounter.next();
  }

  private triggerTimeoutReset() {
    this.timeoutReset.next();
  }
}
