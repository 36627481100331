import { PhoneNumberTypeEnum } from '../enums/phone-number-type.enum';

export class PhoneNumber {
  public phoneNumberID: number = 0;
  public areaCode: string | nil;
  public number: string | nil;
  public extension: string | nil;
  public isTextable: boolean = false;
  public isFaxable: boolean = false;
  public order: number = 0;
  public label: string | nil;
  public phoneNumberType: number = PhoneNumberTypeEnum.Work;

  public copyFromForm(value: any) {
    if (value) {
      const localPhoneNumber: string | nil = value.number ? value.number.replace(/\D/g, '') : null;
      if (localPhoneNumber && localPhoneNumber.length > 0) {
        if (localPhoneNumber.length > 2) {
          this.areaCode = localPhoneNumber.substr(0, 3);
        } else {
          this.areaCode = localPhoneNumber.substr(0);
        }

        if (localPhoneNumber.length > 3) {
          this.number = localPhoneNumber.substr(3);
        }
      } else {
        this.areaCode = null;
        this.number = null;
      }

      this.extension = value.extension;

      if (value.label) {
        this.label = value.label;
      }
    } else {
      this.number = '';
      this.areaCode = '';
      this.extension = '';
    }
  }
}
