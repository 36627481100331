import { Injectable } from '@angular/core';
import { IconType } from '@models/icon/icon.model';

import { PaginatedGridActionColumn, PaginatedGridDataColumn } from '@models/search/paginated-grid-column.model';

@Injectable()
export class PaginatedGridService {
  constructor() {}

  public buildActionColumn(
    icon: IconType,
    rowClick: (element: any) => void,
    tooltip?: string | nil,
    isClickable?: (element: any) => boolean,
  ): PaginatedGridActionColumn {
    return new PaginatedGridActionColumn(icon, rowClick, tooltip ?? '', isClickable);
  }

  public buildDataColumn(
    columnDefinition: string | nil,
    title: string | nil,
    display: (element: any) => string | nil,
    canSort: boolean = true,
    filters: string[] = [],
    useTitleCase: boolean = true,
  ): PaginatedGridDataColumn {
    return new PaginatedGridDataColumn(columnDefinition ?? '', title ?? '', display, canSort, filters, useTitleCase);
  }
}
