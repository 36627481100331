import { Injectable } from '@angular/core';

@Injectable()
export class UIUtilityService {
  constructor() {}

  /** Calculates difference of child element's top value and containing element's top value */
  public calculateDistanceFromTop(element: any, containingElement: any): number {
    let elementOffsetTop = element.offsetTop;
    let containerOffsetTop = containingElement.offsetTop;
    return elementOffsetTop - containerOffsetTop;
  }

  /** Calculates width of element by adding 'offsetWidth' to left and right margin */
  public calculateWidth(element: any): number {
    return (
      element.offsetWidth +
      (parseInt(window.getComputedStyle(element).marginLeft) + parseInt(window.getComputedStyle(element).marginRight))
    );
  }

  /** Calls 'scrollIntoView()' on element with passed in or default parameters */
  public scrollIntoView(
    target: any,
    behavior: string = 'smooth',
    verticalAlignment: string = 'start',
    horizontalAlignment: any = 'nearest',
  ): void {
    target?.scrollIntoView({ behavior: behavior, block: verticalAlignment, inline: horizontalAlignment });
  }

  /** Calls 'scrollTo()' on element with passed in or default parameters */
  public scrollTo(element: any, top: number = 0, left: number = 0, behavior: string = 'smooth'): void {
    element.scrollTo({
      top: top,
      left: left,
      behavior: behavior,
    });
  }
}
