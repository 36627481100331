import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  private messageMap: { [key: string]: string } = {
    '/manuallogout': 'You have successfully logged out',
    '/systemlogout': 'System logout',
    '/timeoutlogout': 'You have been logged out due to inactivity',
  };
  public message: string = '';

  constructor(private router: Router) {}

  ngOnInit() {
    // Get the current route
    const currentRoute = this.router.url;

    // Check if the current route has a specific message
    if (this.messageMap.hasOwnProperty(currentRoute)) {
      this.message = this.messageMap[currentRoute];
    }
  }
}
