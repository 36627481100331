export enum SignalREventType {
  Add = 1,
  Delete = 2,
  Edit = 3
}

export class SignalRNotification<T> {
  public entityID: T | nil;
  public entityName: string = 'Record';
  public eventType: SignalREventType = SignalREventType.Add;
  public notifyUser: boolean = true;
  public userID: string = '';
  public userName: string | nil;
}
