import { Injectable } from '@angular/core';

@Injectable()
export class FileService {
  constructor() {}

  public downloadPrompt(result: any, contentType: string, fileName: string): void {
    const blob = new Blob([result], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 100);
  }
}
