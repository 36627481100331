import { Injectable, inject } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
  HubConnectionState,
  IRetryPolicy,
  RetryContext,
} from '@microsoft/signalr';
import { Subject, Observable, BehaviorSubject, filter, tap, map, throttleTime } from 'rxjs';

import { HealthState } from '@enums/health/health-state.enum';
import { environment } from '@environments/environment';

import { EntityLockWrapper } from '@models/entity-lock/entity-lock-wrapper.model';
import { SignalREventType, SignalRNotification } from '@models/signal-r/signal-r-notification.model';
import { SystemNotificationSignalR } from '@models/signal-r/system-notification.model';
import { SystemStateNotification } from '@models/signal-r/system-state-notification';
import { UserNotification } from '@models/signal-r/user/user-notification.model';

import { AuthService } from '@services/auth.service';
import { SessionContextService } from '@services/session-context.service';
import { SnackbarService } from '@services/snackbar.service';
import { SystemNotificationService } from '@services/system-notification.service';

class RetryPolicy implements IRetryPolicy {
  nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
    switch (retryContext.previousRetryCount) {
      case 0:
        return 0;
      case 1:
        return 2000;
      case 2:
        return 10000;
      case 3:
        return 30000;
      default:
        return 60000;
    }
  }
}

export class SignalRNotificationHandler<T extends SignalRNotification<any> = SignalRNotification<any>> {
  private onMessageReceivedSubject: Subject<T[]>;
  private signalRService: SignalRService;
  private titleCasePipe: TitleCasePipe;
  private userID: string;

  constructor(messageReceived: Subject<T[]>, signalRService: SignalRService) {
    this.userID = inject(AuthService).getSubjectID();
    this.onMessageReceivedSubject = messageReceived;
    this.signalRService = signalRService;
    this.titleCasePipe = inject(TitleCasePipe);
  }

  private get onMessageReceived$(): Observable<T[]> {
    return this.onMessageReceivedSubject.asObservable();
  }

  public onEvent$(eventTypes: SignalREventType[] | SignalREventType = [SignalREventType.Add, SignalREventType.Delete, SignalREventType.Edit]): Observable<T[]> {
    return this.onMessageReceived$.pipe(
      map((value: T[]) => value.filter((x: T) => Array.isArray(eventTypes) ? eventTypes.includes(x.eventType) : x.eventType == eventTypes)),
      filter((value: T[]) => value.length > 0)
    );
  }

  public onNotifyEvent$(eventTypes: SignalREventType[] | SignalREventType = [SignalREventType.Add, SignalREventType.Delete, SignalREventType.Edit]): Observable<T[]> {
    return this.onEvent$(eventTypes).pipe(
      tap((value: T[]) => {
        const filteredNotifications: T[] = value.filter((x: T) => x.notifyUser);
        if (filteredNotifications.length) {
          this.onNotificationReceived(filteredNotifications);
        }
      })
    );
  }

  private onNotificationReceived(notifications: T[]): void {
    const notification: T | nil = notifications[0];
    const action: string = this.setAction(notification.eventType);
    let message: string = `${this.titleCasePipe.transform(notification.entityName)} ${action}`;
    if (notification.notifyUser) {
      if (this.userID != notification.userID) {
        message = `${message} by ${notification?.userName ?? 'User'}`
        this.signalRService.systemNotification = { message: message, entityID: notification.entityID };
      }

      this.signalRService.snackbarMessage = message;
    }
  }

  private setAction(eventType: SignalREventType): string {
    switch (eventType) {
      default:
      case SignalREventType.Add:
        return 'added';
      case SignalREventType.Delete:
        return 'deleted';
      case SignalREventType.Edit:
        return 'edited';
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private clientID: number = 0 //for filtering signalr messages by client when necessary
  private connection: HubConnection | nil;
  private readonly health: BehaviorSubject<HealthState> = new BehaviorSubject<HealthState>(HealthState.Unhealthy);
  private readonly connected: Subject<void> = new Subject();
  private readonly reconnected: Subject<string> = new Subject();
  private readonly snackbarMessageSubject: Subject<string> = new Subject<string>();
  private readonly systemNotificationSubject: Subject<{ message: string, entityID: any }> = new Subject<{ message: string, entityID: any }>();
  public snackbarMessage$: Observable<string>;
  public systemNotification$: Observable<{ message: string, entityID: any }>;

  //#region Subjects

  private readonly clientEventSubject: Subject<SignalRNotification<number>[]> = new Subject<SignalRNotification<number>[]>();
  private readonly facilityEventSubject: Subject<SignalRNotification<number>[]> = new Subject<
    SignalRNotification<number>[]
  >();
  private readonly measureConfigEventSubject: Subject<SignalRNotification<number>[]> = new Subject<SignalRNotification<number>[]>();
  private readonly measureReportEventSubject: Subject<SignalRNotification<number>[]> = new Subject<SignalRNotification<number>[]>();
  private readonly monthEndEventSubject: Subject<SignalRNotification<number>[]> = new Subject<
    SignalRNotification<number>[]
  >();
  private readonly practiceEventSubject: Subject<SignalRNotification<number>[]> = new Subject<
    SignalRNotification<number>[]
  >();
  private readonly providerEventSubject: Subject<SignalRNotification<number>[]> = new Subject<
    SignalRNotification<number>[]
  >();
  private readonly userEventSubject: Subject<UserNotification[]> = new Subject<
    UserNotification[]
  >();

  private readonly entityLockPull: Subject<EntityLockWrapper> = new Subject<EntityLockWrapper>();
  private readonly entityLockDisconnected: Subject<string> = new Subject<string>();
  private readonly systemState: Subject<SystemStateNotification[]> = new Subject<SystemStateNotification[]>();

  //#endregion

  //#region Handlers

  public readonly clientHandler: SignalRNotificationHandler = new SignalRNotificationHandler(this.clientEventSubject, this);
  public readonly facilityHandler: SignalRNotificationHandler = new SignalRNotificationHandler(this.facilityEventSubject, this);
  public readonly measureConfigHandler: SignalRNotificationHandler = new SignalRNotificationHandler(this.measureConfigEventSubject, this);
  public readonly measureReportHandler: SignalRNotificationHandler = new SignalRNotificationHandler(this.measureReportEventSubject, this);
  public readonly monthEndHandler: SignalRNotificationHandler = new SignalRNotificationHandler(this.monthEndEventSubject, this);
  public readonly practiceHandler: SignalRNotificationHandler = new SignalRNotificationHandler(this.practiceEventSubject, this);
  public readonly providerHandler: SignalRNotificationHandler = new SignalRNotificationHandler(this.providerEventSubject, this);
  public readonly userHandler: SignalRNotificationHandler<UserNotification> = new SignalRNotificationHandler<UserNotification>(this.userEventSubject, this);

  //#endregion

  public readonly health$: Observable<HealthState> = this.health.asObservable();
  public readonly connected$: Observable<void> = this.connected.asObservable();
  public readonly entityLockPull$: Observable<EntityLockWrapper> = this.entityLockPull.asObservable();
  public readonly entityLockDisconnected$: Observable<string> = this.entityLockDisconnected.asObservable();
  public readonly reconnected$: Observable<string> = this.reconnected.asObservable();
  public readonly systemState$: Observable<SystemStateNotification[]> = this.systemState.asObservable();

  constructor(private authService: AuthService,
    private sessionContextService: SessionContextService,
    private snackbarService: SnackbarService,
    private systemNotificationService: SystemNotificationService) {
    this.systemNotification$ = this.systemNotificationSubject.asObservable().pipe(
      throttleTime(600),
      tap((value: { message: string, entityID: any }) => {
        this.systemNotificationService.addSimpleNotification(value.message, null, value.entityID);
      })
    );

    this.snackbarMessage$ = this.snackbarMessageSubject.asObservable().pipe(
      throttleTime(600),
      tap((value: string) => {
        this.snackbarService.openFromComponent(value);
      })
    );
  }

  //#region Get/Set

  public get isConnected(): boolean {
    return (this.connection && this.connection.state == HubConnectionState.Connected) ?? false;
  }

  public get connectionID(): string | null {
    if (this.connection) {
      return this.connection.connectionId;
    }

    return null;
  }

  public set snackbarMessage(value: string) {
    this.snackbarMessageSubject.next(value);
  }

  public set systemNotification(value: { message: string, entityID: any }) {
    this.systemNotificationSubject.next(value);
  }

  //#endregion

  //#region Connection

  public connect(clientID: number | nil = null): Promise<void> {
    if (!this.connection) {
      this.buildConnection();
    }

    if (!this.isConnected && this.connection) {
      return this.connection
        .start()
        .then(() => {
          this.health.next(HealthState.Healthy);
          this.connection?.onreconnected((connectionID: string | nil) => this.reconnected.next(connectionID ?? ''));
          if (clientID) {
            this.send('ConnectToClient', clientID.toString());
          }
          this.connected.next();
        })
        .catch((err) => {
          console.log('error:', err);
          this.reconnect();
        });
    }

    return Promise.resolve();
  }

  public disconnect(): void {
    if (this.isConnected) {
      this.connection?.stop();
    }
  }

  public send(event: string, value: any): Promise<void> {
    return this.connection
      ? this.connection.send(event, value).catch((err) => {
        if (err)
          console.error(err);
      })
      : Promise.resolve();
  }

  public invoke(methodName: string, value: any): Promise<any> {
    return this.connection
      ? this.connection.invoke(methodName, value).catch((err) => {
        if (err) console.error(err);
      })
      : Promise.resolve();
  }

  private getAccessToken(): string | Promise<string> {
    return this.sessionContextService.accessToken;
  }

  private buildConnection(): void {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.ApolloAPI}/hub`, {
        skipNegotiation: false,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: this.getAccessToken.bind(this),
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect(new RetryPolicy())
      .build();

    this.connection.onreconnecting((error?: Error) => {
      if (error) {
      }
      this.health.next(HealthState.Unhealthy);
    });
    this.connection.onreconnected((connectionID: string | nil) => {
      this.health.next(HealthState.Healthy);
      this.reconnected.next(connectionID ?? '');
    });
    this.connection.onclose((error?: Error) => {
      if (error) {
        console.error(error);
      }
      this.health.next(HealthState.Unhealthy);
    });
  }

  private reconnect(): void {
    setTimeout(() => this.connect(), 3000);
  }

  //#endregion

  public setHandlers(clientID: number = 0): void {
    if (!this.connection) {
      return;
    }

    this.clientID = clientID;
    this.setupConnection('Client', this.clientEventSubject);
    this.setupConnection('User', this.userEventSubject);

    this.connection.on('EntityLockPull', (notification: EntityLockWrapper) => this.entityLockPull.next(notification));
    this.connection.on('EntityLockDisconnected', (connectionID: string) =>
      this.entityLockDisconnected.next(connectionID),
    );
    this.connection.on('SystemDeploymentAlert', () => {
      const message: string = "The site will be down in 15 minutes for maintenance and you will be logged out.";
      this.snackbarMessage = message;
      this.systemNotification = { message: message, entityID: -1 };
    });
    this.connection.on('SystemDeploymentLogout', () => {
      this.snackbarMessage = "You will be logged out now.";
      setTimeout(() => {
        this.authService.logOut(false, true);
      }, 1000);
    });
    this.connection.on('SystemNotification', (notifications: SystemNotificationSignalR[]) => {
      this.systemNotificationService.onUpdate(notifications, clientID);
    });
    this.connection.on('SystemState', (notifications: SystemStateNotification[]) =>
      this.systemState.next(notifications),
    );

    // specific view handlers
    if (clientID) {
      this.setClientHandlers();
    }
  }

  public setClientHandlers(): void {
    this.setupConnection('Facility', this.facilityEventSubject);
    this.setupConnection('MonthEnd', this.monthEndEventSubject);
    this.setupConnection('Practice', this.practiceEventSubject);
    this.setupConnection('Provider', this.providerEventSubject);
    this.setupConnection('MeasureConfiguration', this.measureConfigEventSubject);
    this.setupConnection('MeasuresReport', this.measureReportEventSubject);
  }

  private setupConnection(event: string, subject: Subject<any>) {
    if (this.connection) {
      this.connection.on(event, (notifications: SignalRNotification<any>[]) => {
        subject.next(notifications);
      });
    }
  }
}
