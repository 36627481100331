import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform, LOCALE_ID } from '@angular/core';

import { DateService } from '@services/utility/date.service';
import { RegularExpressionService } from '@services/utility/regular-expression.service';

@Pipe({
  name: 'dateTimeString',
  pure: false,
})
export class DateTimeStringPipe extends DatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    dateService: DateService,
    private regularExpressionService: RegularExpressionService,
  ) {
    super(locale);
  }
  override transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  override transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  override transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null {
    if (value && typeof value == 'string') {
      let match: RegExpMatchArray | nil = this.regularExpressionService.getMatch(
        this.regularExpressionService.dateTimePattern,
        value,
      );

      if (match && match.length) {
        let date: Date = new Date(match[0]);
        if (!format) format = 'MM/dd/yyyy hh:mm:ss a';

        // Value comes in as UTC, but new Date creates that UTC time in local time
        // so we need to apply the actual offset for the local time.
        date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

        value = value.replace(match[0], super.transform(date, format) ?? '');

        return value;
      }
    }

    return value?.toString() ?? '';
  }
}
