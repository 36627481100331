import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'age' })
export class AgePipe implements PipeTransform {
  private months: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  transform(startDateParam: Date | nil, endDateParam?: Date | string | nil, removeLabel: boolean = false): string {
    if (removeLabel) return this.transformAsNumber(startDateParam, endDateParam).toString();
    else return this.transformAsDisplayString(startDateParam, endDateParam);
  }

  private transformAsDisplayString(startDateParam: Date | nil, endDateParam?: Date | string | nil): string {
    let age: string = '';
    let startDate: Date | nil = startDateParam;
    let endDate: Date | nil;

    if (endDateParam) {
      if (endDateParam instanceof Date) {
        endDate = endDateParam;
      } else {
        const endDateParamString: string = <string>endDateParam;

        endDate = new Date(
          parseInt(endDateParamString.substr(0, 4)),
          parseInt(endDateParamString.substr(4, 2)) - 1,
          parseInt(endDateParamString.substr(6, 2)),
        );
      }
    }

    if (startDate) {
      let today: Date = endDate ? endDate : new Date();
      let yearDiff = today.getFullYear() - startDate.getFullYear();
      let monthDiff = today.getMonth() - startDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < startDate.getDate())) {
        yearDiff--;
      }

      let months: number =
        today.getMonth() + today.getFullYear() * 12 - (startDate.getMonth() + startDate.getFullYear() * 12);

      if (today.getDate() < startDate.getDate()) months--;

      if (months >= 24) {
        age = yearDiff.toString() + ' years';
        months = months - yearDiff * 12;
        if (months > 0) age += ' ' + months.toString() + (months == 1 ? ' month' : ' months');
      } else {
        let timeDiff: number = (today.getTime() - startDate.getTime()) / 1000;
        if (timeDiff < 0) timeDiff = 0;

        let weeks: number = Math.floor(timeDiff / (60 * 60 * 24 * 7));

        if (weeks >= 4) {
          if (months >= 1) {
            age = months.toString() + (months == 1 ? ' month' : ' months');

            let daysBetweenDiff: number = 0;
            if (today.getDate() >= startDate.getDate()) daysBetweenDiff = today.getDate() - startDate.getDate();
            else
              daysBetweenDiff = this.daysBetweenMonths(
                startDate.getMonth(),
                startDate.getDate(),
                today.getMonth(),
                today.getDate(),
              );

            let weeksDiff = Math.abs(Math.floor(daysBetweenDiff / 7));

            if (weeksDiff > 0) age += ' ' + weeksDiff.toString() + (weeksDiff > 1 ? ' weeks' : ' week');
          } else age = weeks.toString() + (weeks > 1 ? ' weeks' : ' week');
        } else {
          let days: number = Math.ceil(timeDiff / (60 * 60 * 24));

          if (days >= 7) {
            days = days - weeks * 7;
            if (days == 7) {
              days = 0;
              weeks++;
            }
            age = weeks.toString() + (weeks > 1 ? ' weeks' : ' week');

            if (days > 0) age += ' ' + days.toString() + (days > 1 ? ' days' : ' day');
          } else {
            age = days.toString() + (days > 1 ? ' days' : ' day');
          }
        }
      }

      if (age.length > 0) age += ' old';
    }
    return age;
  }

  private transformAsNumber(startDateParam: Date | nil, endDateParam?: Date | string | nil): number {
    let age: number = 0;
    let startDate: Date | nil = startDateParam;
    let endDate: Date | nil;

    if (endDateParam) {
      if (endDateParam instanceof Date) {
        endDate = endDateParam;
      } else {
        const endDateParamString: string = <string>endDateParam;

        endDate = new Date(
          parseInt(endDateParamString.substr(0, 4)),
          parseInt(endDateParamString.substr(4, 2)) - 1,
          parseInt(endDateParamString.substr(6, 2)),
        );
      }
    }

    if (startDate) {
      let today: Date = endDate ? endDate : new Date();
      let yearDiff = today.getFullYear() - startDate.getFullYear();
      let monthDiff = today.getMonth() - startDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < startDate.getDate())) {
        yearDiff--;
      }

      let months: number =
        today.getMonth() + today.getFullYear() * 12 - (startDate.getMonth() + startDate.getFullYear() * 12);

      if (today.getDate() < startDate.getDate()) months--;
      months = months - yearDiff * 12;

      age = yearDiff + months / 12;
    }

    return age;
  }

  private daysBetweenMonths(startmonth: number, startday: number, currentMonth: number, currentDay: number): number {
    let totalDays: number = 0;

    if (startmonth == 0) startmonth = 11;

    totalDays = this.months[currentMonth - 1] - startday;
    totalDays += currentDay;

    return totalDays;
  }
}
