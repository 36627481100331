import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@models/address.model';

@Pipe({
  name: 'addressText',
  pure: false,
})
export class AddressTextPipe implements PipeTransform {
  transform(value: Address): string {
    let returnVal: string = '';

    if (value) {
      if (value.line1) {
        returnVal += `${value.line1}`;
      }

      if (value.line2) {
        returnVal += `\n${value.line2}`;
      }

      if (value.city || value.stateProvinceCode || value.postalCode) {
        let bottomLine: string = '';

        if (value.city && value.stateProvinceCode && value.postalCode) {
          bottomLine += `${value.city}, ${value.stateProvinceCode} `;
        } else {
          if (value.city) {
            bottomLine += `${value.city}`;
          } else if (value.stateProvinceCode) {
            bottomLine += `${value.stateProvinceCode}`;
          }
        }

        if (value.postalCode) {
          if (bottomLine && !bottomLine.endsWith(' ')) {
            bottomLine += ' ';
          }

          bottomLine +=
            value.postalCode.length > 5
              ? `${value.postalCode.slice(0, 5)}` + '-' + `${value.postalCode.slice(5)}`
              : `${value.postalCode}`;
        }

        returnVal += `\n${bottomLine}`;
      }
    }

    return returnVal;
  }
}
