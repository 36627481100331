import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Observable, BehaviorSubject, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';

import { ApplicationAreas } from '@enums/application-area-enum';
import { UserPermissionEnum, UserPermissionLevelEnum } from '@enums/user-permission.enum';

import {
  PermissionCategory,
  UserPermissionLevel,
  UserRole,
  UserRoleSetting,
  UserSecurity,
} from '@models/user/user-role.model';
import { SignalREventType } from '@models/signal-r/signal-r-notification.model';
import { UserNotification } from '@models/signal-r/user/user-notification.model';

import { ApiAccessService } from '@services/api-access.service';
import { AuthService } from '@services/auth.service';
import { ClientConnectionService } from '@services/client-connection.service';
import { SessionContextService } from '@services/session-context.service';
import { SignalRService } from '@services/signal-r.service';
import { SystemNotificationService } from '@services/system-notification.service';

@Injectable({
  providedIn: 'root',
})
export class UserSecurityService implements OnDestroy {
  private readonly currentUserPermissionsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly currentUserPermissionsLoaded$: Observable<boolean> = this.currentUserPermissionsLoaded.asObservable();

  //roles and permissions for the current logged in user
  public currentUserRoles: UserRole[] = [];
  public currentUserPermissions: UserPermissionLevel[] = [];
  public practiceSelections: number[] = [];
  public securityToken: string | nil = null;

  private controller: string = 'userSecurity';
  private subscription: Subscription = new Subscription();

  public static SystemAdminTypeName: string = 'stimaster';

  constructor(
    private auth: AuthService,
    private apiAccessService: ApiAccessService,
    private signalRService: SignalRService,
    private notifications: SystemNotificationService,
    private clientConnectionService: ClientConnectionService,
    private sessionCtx: SessionContextService,
  ) {
    this.subscription.add(
      this.signalRService.userHandler
        .onEvent$(SignalREventType.Edit)
        .pipe(
          tap((notifications: UserNotification[]) => {
            if (notifications?.length) {
              let hasRoleChanges: boolean = false;
              notifications.forEach((n: UserNotification) => {
                if (n.entityID === this.auth.getSubjectID()) {
                  if (n.hasRoleChanges) {
                    hasRoleChanges = true;
                  }
                }
              });

              if (hasRoleChanges) {
                if (this.clientConnectionService.isConnected()) {
                  //notify user of role changes
                  this.SendNotificationForUpdatedRoles(this.currentUserRoles.map((r) => r.userRoleID ?? 0));
                } else {
                  //refresh page
                  window.location.reload();
                }
              }
            }
          }),
          switchMap(() => this.sessionCtx.getAvailableClientList()),
        )
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //current user role/permissions
  public loadCurrentUserPermissions(): Observable<any> {
    return this.apiAccessService.get(this.controller, 'GetUserSecurity', null, false, UserSecurity).pipe(
      switchMap((data: UserSecurity) => {
        if (data) {
          this.currentUserPermissions = data.permissions;
          this.currentUserRoles = data.roleSelections;
          this.practiceSelections = data.practiceSelections;
          this.securityToken = data.securityToken;

          this.sessionCtx.permissions = this.currentUserPermissions.sort(
            (a, b) => a.userPermissionID - b.userPermissionID,
          );
          this.sessionCtx.securityToken = this.securityToken ?? '';

          return this.sessionCtx.getAvailableClientList().pipe(
            finalize(() => {
              this.currentUserPermissionsLoaded.next(true);
            }),
          );
        }

        return of(null);
      }),
    );
  }

  //client roles
  public getClientUserRoles(): Observable<UserRole[]> {
    return this.apiAccessService.get(this.controller, 'GetClientUserRoles', null, false, UserRole);
  }

  //settings
  public getUserRolesForSettings(): Observable<UserRoleSetting[]> {
    return this.apiAccessService.get(this.controller, 'GetUserRoleSettings', null, false, UserRoleSetting);
  }

  //permissions edit screen for role settings
  public getPermissionSettings(): Observable<PermissionCategory[]> {
    return this.apiAccessService.get(this.controller, 'GetPermissionSettings', null, true, PermissionCategory);
  }

  public save(role?: UserRole | nil): Observable<UserRole | nil> {
    if (role) {
      let params: Map<string, string> = new Map();
      params.set('userRoleVMJSON', JSON.stringify(role));

      let method: string = role.userRoleID ? 'EditRole' : 'AddRole';
      return this.apiAccessService.post(this.controller, method, params, UserRole);
    } else return of(null);
  }

  public remove(role?: UserRole | nil): Observable<boolean> {
    if (role) {
      return this.apiAccessService
        .delete(this.controller, 'RemoveRole', role.userRoleID?.toString() ?? '')
        .pipe(map(() => true));
    } else return of(false);
  }

  //#region Utility

  public hasPermission(permission: UserPermissionEnum, level: UserPermissionLevelEnum | number): boolean {
    if (permission) return this.hasPermissions([new UserPermissionLevel(permission, level)]);
    else return false;
  }

  public hasPermissions(permissions: UserPermissionLevel[]): boolean {
    let hasPermission: boolean = true;

    if (permissions?.length) {
      permissions.forEach((p) => {
        const userPermissions: UserPermissionLevel | nil = this.currentUserPermissions.find(
          (l) => l.userPermissionID == p.userPermissionID && l.level >= p.level,
        );
        if (!userPermissions) {
          hasPermission = false;
        }
      });
    }

    return hasPermission;
  }

  public hasClientSettingsAccess(): boolean {
    return (
      this.hasPermission(UserPermissionEnum.practiceSettings, UserPermissionLevelEnum.view) ||
      this.hasPermission(UserPermissionEnum.providerSettings, UserPermissionLevelEnum.view) ||
      this.hasPermission(UserPermissionEnum.codes, UserPermissionLevelEnum.view) ||
      this.hasPermission(UserPermissionEnum.users, UserPermissionLevelEnum.view) ||
      this.hasPermission(UserPermissionEnum.roles, UserPermissionLevelEnum.view)
    );
  }

  public hasConsoleSettingsAccess(): boolean {
    return (
      this.hasPermission(UserPermissionEnum.codes, UserPermissionLevelEnum.view) ||
      this.hasPermission(UserPermissionEnum.consoleUsers, UserPermissionLevelEnum.view) ||
      this.hasPermission(UserPermissionEnum.systemStatus, UserPermissionLevelEnum.view)
    );
  }

  public SendNotificationForUpdatedRoles(roleIDs: number[]) {
    const roles: UserRole[] = this.currentUserRoles.filter((r) => roleIDs.indexOf(r.userRoleID ?? 0) > -1);
    if (roles?.length) {
      this.notifications.addSimpleNotification(
        'Your permissions have been updated. Please log out and back in for changes to take effect.',
        null,
        null,
        ApplicationAreas.Account,
      );
    }
  }

  //#endregion
}
