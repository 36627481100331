import { Injectable } from '@angular/core';
import { ValidationErrors, Validators } from '@angular/forms';

import { ValidationMessage } from '@models/validation-messages.model';

@Injectable()
export class ValidationMessageService {
  public readonly dobMessage: string = 'Occurs before DOB';
  public readonly historyConflictMessage: string = 'Conflicts with history';

  private validationMessageMap: Map<Validators, (errors: ValidationErrors) => string> = new Map<
    Validators,
    (errors: ValidationErrors) => string
  >();

  constructor() {
    this.setValidationMap();
  }

  /** Gets message associated with specific 'ValidationError' */
  public getValidationMessage(errors: ValidationErrors): string | nil {
    let validator: Validators | nil = this.getValidator(errors);
    let message: string | nil = 'Invalid';

    if (validator) message = this.validationMessageMap.get(validator)?.call(null, errors);
    else if ((errors as ValidationMessage).message) message = (errors as ValidationMessage).message;

    return message?.trim();
  }

  /** Gets 'Validator' associated with specific 'ValidationError' */
  public getValidator(errors: ValidationErrors): Validators | nil {
    if (errors['email']) return Validators.email;
    else if (errors['max']) return Validators.max;
    else if (errors['maxlength']) return Validators.maxLength;
    else if (errors['min']) return Validators.min;
    else if (errors['minlength']) return Validators.minLength;
    else if (errors['pattern']) return Validators.pattern;
    else if (errors['required']) return Validators.required;
    else return null;
  }

  /** Set 'validationMessageMap' object for each built in 'Validators' option */
  private setValidationMap(): void {
    this.validationMessageMap.set(Validators.email, () => 'Invalid email');
    this.validationMessageMap.set(Validators.max, (errors: ValidationErrors) => this.maxMessage(errors));
    this.validationMessageMap.set(Validators.maxLength, (errors: ValidationErrors) => this.maxLengthMessage(errors));
    this.validationMessageMap.set(Validators.min, (errors: ValidationErrors) => this.minMessage(errors));
    this.validationMessageMap.set(Validators.minLength, (errors: ValidationErrors) => this.minLengthMessage(errors));
    this.validationMessageMap.set(Validators.pattern, () => 'Invalid');
    this.validationMessageMap.set(Validators.required, () => 'Required');
  }

  //#region Message

  /** Returns 'Occurs after {value} date' */
  public maxDateMessage(value: string = 'end'): string {
    return `Occurs after ${value} date`;
  }

  /** Returns 'Occurs before {value} date' */
  public minDateMessage(value: string = 'start'): string {
    return `Occurs before ${value} date`;
  }

  /** Returns '{max} or below' */
  private maxMessage(errors: ValidationErrors): string {
    return `${errors['max']?.max} or below`;
  }

  /** Returns '{min} or above' */
  private minMessage(errors: ValidationErrors): string {
    return `${errors['min']?.min} or above`;
  }

  /** Returns '{maxLength} character max' */
  private maxLengthMessage(errors: ValidationErrors): string {
    return `${errors['maxlength']?.requiredLength} character max`;
  }

  /** Returns '{minLength} character min' */
  private minLengthMessage(errors: ValidationErrors): string {
    return `${errors['minlength']?.requiredLength} character min`;
  }

  //#endregion Message
}
