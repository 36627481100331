import { Injectable } from '@angular/core';

@Injectable()
export class CloneService {
  constructor() {}

  public deepClone(obj: any) {
    // return value if value type, or Date, and is not an Object or Array.
    if (obj instanceof Date) {
      let newDate: Date = new Date(obj as Date);

      return newDate;
    } else if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    let clone;

    if (Array.isArray(obj)) {
      clone = [];
    } else {
      // Use obj.constructor to ensure we don't return anonymous object when source is already typed
      clone = new obj.constructor();
    }

    for (let key in obj) {
      clone[key] = this.deepClone(obj[key]);
    }

    return clone;
  }
}
