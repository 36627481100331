import { Injectable, Optional } from '@angular/core';

import { TimeSpanEnum } from '@enums/time-span.enum';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  public getBrowserStandardTimeOffset(): number {
    let offset: number = -new Date().getTimezoneOffset();

    if (this.isInDaylightSavings()) {
      offset -= 60;
    }

    return offset;
  }

  public getTimezoneID(timeZoneOffset: number): number | nil {
    if (timeZoneOffset) {
      if (timeZoneOffset == -300) {
        return 1;
      } else if (timeZoneOffset == -360) {
        return 2;
      } else if (timeZoneOffset == -420) {
        return 3;
      } else if (timeZoneOffset == -480) {
        return 4;
      } else if (timeZoneOffset == -540) {
        return 5;
      } else if (timeZoneOffset == -600) {
        return 6;
      }
    }

    return null;
  }

  public getTimeZoneOffset(timeZoneID: number): number | nil {
    if (timeZoneID) {
      if (timeZoneID == 1) {
        return -300;
      } else if (timeZoneID == 2) {
        return -360;
      } else if (timeZoneID == 3) {
        return -420;
      } else if (timeZoneID == 4) {
        return -480;
      } else if (timeZoneID == 5) {
        return -540;
      } else if (timeZoneID == 6) {
        return -600;
      }
    }

    return null;
  }

  public getDatePipeTimeZoneOffset(
    timeZoneOffset: number,
    observeDaylightSavings: boolean,
    date: Date = new Date(),
  ): string {
    let daylightSwitch: boolean =
      !observeDaylightSavings && this.isInDaylightSavings() != this.isInDaylightSavings(date);
    let hoursOffsetString: string = (
      (!daylightSwitch && observeDaylightSavings && this.isInDaylightSavings(date)
        ? timeZoneOffset + 60
        : timeZoneOffset) / 60
    ).toString();

    // Add leading zero for negative and non negative offsets.
    if (hoursOffsetString.length == 1) {
      hoursOffsetString = '0' + hoursOffsetString[0];
    } else if (hoursOffsetString.length == 2) {
      hoursOffsetString = hoursOffsetString[0] + '0' + hoursOffsetString[1];
    }

    return hoursOffsetString;
  }

  public getTimeZoneName(timeZoneOffset: number | nil): string | nil {
    if (timeZoneOffset) {
      if (timeZoneOffset == -300) {
        return 'Eastern Time';
      } else if (timeZoneOffset == -360) {
        return 'Central Time';
      } else if (timeZoneOffset == -420) {
        return 'Mountain Time';
      } else if (timeZoneOffset == -480) {
        return 'Pacific Time';
      } else if (timeZoneOffset == -540) {
        return 'Alaska Time';
      } else if (timeZoneOffset == -600) {
        return 'Hawaii Time';
      }
    }

    return null;
  }

  public getTimeZoneAbbreviation(timeZoneOffset: number | nil, observeDaylightSavings: boolean): string {
    if (timeZoneOffset) {
      if (observeDaylightSavings && this.isInDaylightSavings()) {
        if (timeZoneOffset == -300) {
          return 'EDT';
        } else if (timeZoneOffset == -360) {
          return 'CDT';
        } else if (timeZoneOffset == -420) {
          return 'MDT';
        } else if (timeZoneOffset == -480) {
          return 'PDT';
        } else if (timeZoneOffset == -540) {
          return 'AKDT';
        } else if (timeZoneOffset == -600) {
          return 'HST';
        }
      } else {
        if (timeZoneOffset == -300) {
          return 'EST';
        } else if (timeZoneOffset == -360) {
          return 'CST';
        } else if (timeZoneOffset == -420) {
          return 'MST';
        } else if (timeZoneOffset == -480) {
          return 'PST';
        } else if (timeZoneOffset == -540) {
          return 'AKST';
        } else if (timeZoneOffset == -600) {
          return 'HST';
        }
      }
    }

    let isDaylightSavings: boolean = this.isInDaylightSavings();

    return isDaylightSavings ? 'EDT' : 'EST';
  }

  public isInDaylightSavings(currentDate: Date = new Date()): boolean {
    currentDate = currentDate ? currentDate : new Date();

    let janDate = new Date(currentDate.getFullYear(), 0, 1).getTimezoneOffset();
    let julDate = new Date(currentDate.getFullYear(), 6, 1).getTimezoneOffset();

    return Math.max(janDate, julDate) != currentDate.getTimezoneOffset();
  }

  /** Gets 'Date' object from string representation of date. The convert to local field should be true if you are using this function to convert the date for a reported date field */
  public getDateFromEstimate(
    estimatedDate: string | nil,
    isEndDate: boolean = false,
    convertToLocalTime = false,
  ): Date | nil {
    let date: Date | nil;

    if (estimatedDate) {
      // Estimated date should be in a yyyyMMdd, yyyyMM, or yyyy format.
      let year: number = 0;
      let month: number = 1;
      let day: number = 1;

      if (estimatedDate.length >= 4) {
        year = parseInt(estimatedDate.substr(0, 4));

        if (estimatedDate.length >= 6) {
          month = parseInt(estimatedDate.substr(4, 2));

          if (estimatedDate.length === 8) {
            day = parseInt(estimatedDate.substr(6, 2));
          }
        }
      }

      date = new Date(year, month - 1, day);

      if (isEndDate) {
        if (estimatedDate.length === 6) {
          // Get last day of month.  Month is 0 based, and passing 0 for the day gives you the last day of the previous month.
          // So by not subtracting 1 from the month, we get the next month from the current month, and passing 0 gives us the last
          // day of the previous month, which is the actual month we're using.
          var tempDate = new Date(year, month, 0);

          day = tempDate.getDate();
        } else if (estimatedDate.length === 4) {
          month = 12;
          day = 31;
        }

        date = new Date(year, month - 1, day, 23, 59, 59, 999);
      }
    }

    return date;
  }

  /** Gets the time between dates passed in. If no endDate, we assume the endDate is now */
  public getTimeBetweenDates(
    startDate: Date | nil,
    endDate: Date = new Date(),
    timeSpan: TimeSpanEnum = TimeSpanEnum.Months,
  ): number {
    let timeSince: number = 0;
    if (startDate) {
      switch (timeSpan) {
        case TimeSpanEnum.Days: {
          timeSince = (endDate.valueOf() - startDate.valueOf()) / (1000 * 60 * 60 * 24);
          break;
        }
        case TimeSpanEnum.Months: {
          timeSince = (endDate.valueOf() - startDate.valueOf()) / (1000 * 60 * 60 * 24 * 30);
          break;
        }
        case TimeSpanEnum.Years: {
          timeSince = (endDate.valueOf() - startDate.valueOf()) / (1000 * 60 * 60 * 24 * 365);
          break;
        }
      }
    }

    return timeSince;
  }

  public hasReportedDate(data: any): boolean {
    let result: boolean = false;

    if (data) {
      for (const key of Object.keys(data)) {
        if (key === 'reportedDate') {
          result = true;

          break;
        } else if (typeof data[key] === 'object') {
          result = result || this.hasReportedDate(data[key]);
        }
      }
    }

    return result;
  }

  /** Returns original date with hours/minutes/seconds/milliseconds = 0 */
  public setBeginningOfDay(originalDate: Date | nil): Date {
    let newDate: Date = originalDate ? new Date(originalDate) : new Date();
    newDate.setHours(0, 0, 0, 0);

    return newDate;
  }

  /** Returns original date with hours = 23, and minutes/seconds/milliseconds = 59 */
  public setEndOfDay(originalDate: Date | nil): Date {
    let newDate: Date = originalDate ? new Date(originalDate) : new Date();
    newDate.setHours(23, 59, 59, 59);

    return newDate;
  }
}
