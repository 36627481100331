import { Pipe, PipeTransform } from '@angular/core';
import { Client } from '@models/client/client.model';

@Pipe({ name: 'clientList' })
export class ClientListPipe implements PipeTransform {
  transform(value?: Client[] | nil): string {
    if (!value) {
      return '';
    } else {
      return value
        .map(function (value) {
          return value.displayName;
        })
        .join(', ');
    }
  }
}
