import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { HealthState } from '@enums/health/health-state.enum';
import { HealthType } from '@enums/health/health-type.enum';

import { SignalRService } from '@services/signal-r.service';
import { SystemStateNotification } from '@models/signal-r/system-state-notification';

@Injectable()
export class HealthService implements OnDestroy {
  private readonly subscription: Subscription = new Subscription();
  private readonly state: BehaviorSubject<HealthState> = new BehaviorSubject<HealthState>(HealthState.Unhealthy);
  private readonly states: BehaviorSubject<Map<HealthType, HealthState>> = new BehaviorSubject(new Map());
  private readonly statesMap: Map<HealthType, HealthState> = new Map();

  public readonly state$: Observable<HealthState> = this.state.asObservable();
  public readonly states$: Observable<Map<HealthType, HealthState>> = this.states.asObservable();

  constructor(private signalRService: SignalRService) {
    this.add(HealthType.SignalR, this.signalRService.health$);
    this.add(
      HealthType.USPS,
      this.signalRService.systemState$.pipe(
        filter((x) => !!(x && x.length && x.find((y: SystemStateNotification) => y.type === HealthType.USPS))),
        map((x) => x[0].state),
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private add(type: HealthType, indicator: Observable<HealthState>): void {
    if (this.statesMap.has(type)) {
      return;
    }

    this.subscription.add(
      indicator.subscribe((indicatorState: HealthState) => {
        const previousHealthIndicatorState: HealthState | nil = this.statesMap.get(type);

        this.statesMap.set(type, indicatorState);

        if (indicatorState == previousHealthIndicatorState) {
          return;
        }

        this.states.next(this.statesMap);

        const healthyMap: Map<HealthType, HealthState> = new Map();
        const unhealthyMap: Map<HealthType, HealthState> = new Map();

        for (const [key, value] of this.statesMap) {
          switch (value) {
            case HealthState.Healthy:
              healthyMap.set(key, value);

              break;
            case HealthState.Unhealthy:
              unhealthyMap.set(key, value);

              break;
          }
        }

        const state: HealthState = unhealthyMap.size ? HealthState.Unhealthy : HealthState.Healthy;

        if (this.state.value != state) {
          this.state.next(state);
        }
      }),
    );
  }
}
