import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscription, tap } from 'rxjs';

import { ApplicationAreas } from '@enums/application-area-enum';
import { TaskState } from '@enums/task.enums';

import { SystemNotificationSignalR } from '@models/signal-r/system-notification.model';

import { ApiAccessService } from '@services/api-access.service';
import { AuthService } from '@services/auth.service';

export interface SystemNotification {
  id: number; //local ID using nextSystemNotificationID
  created: Date;

  message: string;
  details: string | nil;

  isRead: boolean;
  isDismissed: boolean;

  associatedEntityID?: number | nil;
  area?: ApplicationAreas | nil;

  //Notifications saved to the DB and optionally attached to Tasks
  notificationID?: number;
  expiration?: Date;
  backgroundTaskID?: number | nil;
  state?: TaskState | nil;
}

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationService implements OnDestroy {
  private readonly controller: string = 'Notifications';
  private readonly subscription: Subscription = new Subscription();
  private readonly systemNotificationsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly systemNotificationAdded: Subject<SystemNotification> = new Subject();
  private readonly persistedSystemNotificationAdded: Subject<SystemNotification> = new Subject();
  private readonly persistedSystemNotificationUpdated: Subject<SystemNotification> = new Subject();
  private readonly systemNotificationUpdated: Subject<SystemNotification> = new Subject();
  private readonly systemNotificationRemoved: Subject<SystemNotification> = new Subject();

  private nextSystemNotificationID: number = 1;
  private hasLoadedPersistedSystemNotifications: boolean = false;

  public systemNotifications: SystemNotification[] = [];

  public readonly systemNotificationsLoaded$: Observable<boolean> = this.systemNotificationsLoaded.asObservable();
  public readonly systemNotificationAdded$: Observable<SystemNotification> =
    this.systemNotificationAdded.asObservable();
  public readonly persistedSystemNotificationAdded$: Observable<SystemNotification> =
    this.persistedSystemNotificationAdded.asObservable();
  public readonly systemNotificationUpdated$: Observable<SystemNotification> =
    this.systemNotificationUpdated.asObservable();
  public readonly persistedSystemNotificationUpdated$: Observable<SystemNotification> =
    this.persistedSystemNotificationUpdated.asObservable();
  public readonly systemNotificationRemoved$: Observable<SystemNotification> =
    this.systemNotificationRemoved.asObservable();

  constructor(
    private authService: AuthService,
    private apiAccessService: ApiAccessService
  ) {
    this.systemNotificationsLoaded.next(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public addSimpleNotification(
    message: string,
    details: string | nil = null,
    associatedEntityID: number | nil = null,
    area: ApplicationAreas | nil = ApplicationAreas.Unknown,
    state: TaskState | nil = null,
  ): SystemNotification {
    let notification: SystemNotification = {
      id: this.nextSystemNotificationID++,
      created: new Date(),
      message: message,
      details: details,
      isDismissed: false,
      isRead: false,
      associatedEntityID: associatedEntityID,
      area: area,
      state: state,
    };

    this.add(notification);
    return notification;
  }

  public addPersistedNotification(
    message: string,
    details: string | nil = null,
    notificationID: number | nil = null,
    area: ApplicationAreas | nil = null,
    state: TaskState | nil = null,
  ): SystemNotification {
    let notification: SystemNotification = {
      id: this.nextSystemNotificationID++,
      message: message,
      created: new Date(),
      details: details,
      isDismissed: false,
      isRead: false,
      notificationID: notificationID ?? 0,
      area: area,
      state: state,
    };
    this.add(notification);
    return notification;
  }

  private add(notification: SystemNotification) {
    this.systemNotifications.push(notification);
    this.systemNotificationAdded.next(notification);
  }

  public update(id: number, message: string, details: string | nil = null, state: TaskState | nil = null): void {
    const index: number = this.systemNotifications.findIndex((n) => n.id == id);
    if (index > -1) {
      const systemNotification: SystemNotification = this.systemNotifications[index];
      if (systemNotification) {
        systemNotification.message = message;
        systemNotification.details = details;
        if (state) {
          systemNotification.state = state;
        }

        if (systemNotification.notificationID) {
          this.editPersistedSystemNotification(systemNotification);
        }
      }
    }
  }

  public remove(id: number): void {
    const index: number = this.systemNotifications.findIndex((x) => x.id == id);

    if (index > -1) {
      const systemNotification: SystemNotification = this.systemNotifications[index];

      if (systemNotification.notificationID) {
        if (
          !systemNotification.state ||
          systemNotification.state == TaskState.Success ||
          systemNotification.state == TaskState.Canceled ||
          systemNotification.state == TaskState.Failure
        )
          this.deleteNotification(systemNotification.notificationID);
        else {
          //hide persisted notification record instead of deletion
          systemNotification.isDismissed = true;
          this.editPersistedSystemNotification(systemNotification);
        }
      } else {
        this.systemNotifications.splice(index, 1);
        this.systemNotificationRemoved.next(systemNotification);
      }
    }
  }

  public deleteNotification(id: number): void {
    this.subscription.add(
      this.apiAccessService.delete(this.controller, 'Delete', id.toString()).subscribe(() => {
        let index: number = this.systemNotifications.findIndex((n) => n.notificationID == id);
        if (index > -1) {
          this.systemNotifications.splice(index, 1);
        }
      }),
    );
  }

  public markAsRead(id: number): void {
    const index: number = this.systemNotifications.findIndex((x) => x.id == id);

    if (index > -1 && !this.systemNotifications[index].isRead) {
      this.systemNotifications[index].isRead = true;

      if (this.systemNotifications[index].notificationID) {
        this.editPersistedSystemNotification(this.systemNotifications[index]);
      }
    }
  }

  public markAllAsRead(): void {
    for (const systemNotification of this.systemNotifications) {
      this.markAsRead(systemNotification.id);
    }
  }

  public clear(): void {
    for (const systemNotification of [...this.systemNotifications]) {
      this.remove(systemNotification.id);
    }
  }

  public onUpdate(notifications: SystemNotificationSignalR[], clientID: number): void {
    //persisted notifications have been updated
    if (notifications) {
      for (let notification of notifications) {
        if (notification.clientID == clientID) {
          // Check for both persisted and non persisted IDs,
          // we filter with the application area.
          const index: number = this.systemNotifications.findIndex(
            (n) =>
              (n.notificationID == notification.entityID || n.id == notification.entityID) && n.area == notification.area,
          );

          if (index > -1) {
            let systemNotification: SystemNotification = this.systemNotifications[index];

            if (systemNotification) {
              systemNotification.message = notification.message;
              systemNotification.state = notification.state;

              if (systemNotification.notificationID) {
                this.persistedSystemNotificationUpdated.next(systemNotification);
              } else {
                this.systemNotificationUpdated.next(systemNotification);
              }
            }
          }
          else if (notification.entityID != -1) {
            this.addPersistedNotification(notification.message, notification.details, notification.entityID, notification.area, notification.state);
          }
          else
            this.addSimpleNotification(notification.message, notification.details, null, notification.area, notification.state);
        }
      }
    }
  }

  private addPersistedSystemNotification(notification: SystemNotification): SystemNotification {
    let index: number = this.systemNotifications.findIndex((n) => n.notificationID == notification.notificationID);
    if (index < 0) {
      notification.id = this.nextSystemNotificationID++;
      this.systemNotifications.push(notification);
      this.systemNotificationAdded.next(notification);
    }
    return notification;
  }

  private editPersistedSystemNotification(notification: SystemNotification) {
    if (notification.notificationID) {
      let map = new Map();
      map.set('notificationVMJSON', JSON.stringify(notification));

      this.subscription.add(
        this.apiAccessService.post(this.controller, 'Update', map).subscribe(() => { }),
      );
    }
  }

  public getPersistedNotificationList(): Observable<any> {
    return this.apiAccessService.get(this.controller, 'GetNotifications').pipe(tap((results: SystemNotification[]) => {
      for (const result of results) {
        this.addPersistedSystemNotification(result);
      }

      this.systemNotificationsLoaded.next(true);
    }));
  }
}
