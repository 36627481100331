import { typeMap } from '../model-mapper';
import { Practice } from '../practice.model';

export class PM_DataSource {
  public dataSourceID: number = 0;
  public hdsNumber: string = '';
  public dataSourceName: string = '';
  public hasAssignedPractice: boolean = false;
  @typeMap(Practice)
  public practices: Practice[] = [];
}

export class ClientAssignment {
  public clientAssignmentID: number = 0;
  public clientID: number = 0;

  @typeMap(PM_DataSource)
  public dataSource: PM_DataSource | nil = null;
  @typeMap(Practice)
  public practice: Practice | nil = null;
}
