import { Pipe, PipeTransform } from '@angular/core';

import { Provider } from '@models/provider/provider.model';

import { PersonNamePipe } from '@pipes/person-name.pipe';

@Pipe({
  name: 'providerName',
  pure: false,
})
export class ProviderNamePipe implements PipeTransform {
  constructor(private personNamePipe: PersonNamePipe) {}

  transform(
    provider: Provider,
    includeIHCode: boolean = true,
    includeMiddle: boolean = false,
    includeSuffix: boolean = true,
    includeDegree: boolean = false,
    lastNameFirst: boolean = false,
  ): string {
    let name: string = '';

    if (provider && provider.person) {
      name = this.personNamePipe.transform(provider.person, includeMiddle, includeSuffix, lastNameFirst);

      if (includeDegree && provider.degree && name.length > 0) {
        name = `${name} ${provider.degree}`;
      }

      if (includeIHCode && provider.providerIHCodes.length && name.length > 0) {
        name = `${name} (${provider.ihCodes.join(', ')})`;
      }
    }

    return name;
  }
}
