import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'userTypeDisplay' })
export class UserTypeDisplayPipe implements PipeTransform {
  transform(value?: string | nil): string {
    switch (value) {
      case 'client':
        return 'Client';
      case 'stistandard':
        return 'Standard';
      case 'stimaster':
        return 'System Administrator';
      case 'system':
        return 'System';
      default:
        return '';
    }
  }
}
