<div class="content">
    <ng-content></ng-content>
    <div class="graphic-container">
        <img
            title="Analytics"
            src="../../assets/images/AnalyticsGraphic1.png" />
        <div class="blurb-container">
            <span class="title">Ascentia Analytics & Reporting</span>
            <span
                >Ascentia Analytics & Reporting tools deliver actionable insights to achieve optimal financial health.
                An intuitive analytics dashboard and detailed reporting provide full transparency into RCM
                processes.</span
            >
            <span
                >Turnkey metrics are available for performance areas including Productivity, Billing, Revenue, AR, and
                Denials.</span
            >
        </div>
    </div>
    <div class="blurb-container center">
        <span class="title">Revenue Cycle Optimization</span>
        <span
            >STI’s Revenue Cycle Management Division (STI RCM) leverages our advanced Ascentia Analytics & Reporting
            software to deliver better financial results for our clients with a concept that we call Revenue Cycle
            Optimization (RCO).</span
        >
        <span
            >From claims submission to denials management, we can help increase revenue, accelerate payments and
            collections, minimize denials, reduce costs, and provide insightful KPIs on a practice’s financial and
            operational performance</span
        >
    </div>
    <div class="button-container">
        <button
            type="button"
            mat-flat-button
            class="alert-button"
            (click)="onClick()">
            Learn More
        </button>
    </div>
</div>
<div class="footer">
    <div class="footer-content">
        <div class="footer-section">
            <span>About Us</span>
            <span
                >For over 40 years, STI Computer Services has been your healthcare partner providing and pioneering the
                latest software, technology and support to thousands of ambulatory medical practices. Learn more about
                how we are advancing the delivery of ambulatory healthcare for clinicians with Service, Technology and
                Innovation.</span
            >
        </div>
        <div class="footer-section">
            <span>Solutions</span>
            <a href=" https://sticomputer.com/solutions/chartmaker-go/">ChartMaker® GO</a>
            <a href=" https://sticomputer.com/clinical-documentation-improvement-program/"
                >Clinical Documentation Improvement</a
            >
            <a href=" https://sticomputer.com/solutions/cloud-services/">Cloud Services</a>
            <a href=" https://sticomputer.com/solutions/credentialing/">Credentialing Services</a>
            <a href=" https://sticomputer.com/dragon-dictation/">Dragon Dictation</a>
            <a href=" https://sticomputer.com/solutions/efaxing/">eFaxing</a>
            <a href=" https://sticomputer.com/solutions/electronic-health-records/">Electronic Health Records</a>
            <a href=" https://sticomputer.com/health-portal/">Health Portal</a>
            <a href=" https://sticomputer.com/solutions/managed-services/">Managed Services</a>
            <a href=" https://sticomputer.com/solutions/mobile-app/">ChartMaker® Mobile</a>
            <a href=" https://sticomputer.com/solutions/practice-management/">Practice Management</a>
            <a href=" https://sticomputer.com/ppo-instamed/">Patient Pay Online</a>
            <a href=" https://sticomputer.com/solutions/patientportal/">Patient Portal</a>
            <a href=" https://sticomputer.com/solutions/revenue-cycle-management/">Revenue Cycle Management</a>
        </div>
        <div class="footer-section">
            <span>Contact Us</span>
            <span>Corporate Headquarters</span>
            <span>2700 Van Buren Avenue</span>
            <span>Eagleville, PA 19403</span>
            <span>Phone: (800) 487-9135</span>
            <span>Fax: (610) 650-9272</span>
        </div>
    </div>
    <span>© 2023 STI Computer Services. All Rights Reserved.</span>
</div>
