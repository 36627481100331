import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { IconType } from '@models/icon/icon.model';

@Component({
  selector: 'app-snack-bar-annotated',
  templateUrl: './snack-bar-annotated.component.html',
  styleUrls: ['./snack-bar-annotated.component.scss'],
})
export class SnackBarAnnotatedComponent {
  public color: ThemePalette | nil = 'warn';
  public icon: IconType = 'exclamationCircle';
  public message: string = '';

  constructor(public snackBarRef: MatSnackBarRef<any>) {
    this.color = snackBarRef.containerInstance.snackBarConfig.data.color;
    this.icon = snackBarRef.containerInstance.snackBarConfig.data.iconType;
    this.message = snackBarRef.containerInstance.snackBarConfig.data.message;
  }
}
