export const environment = {
  production: true,
  BaseURI: 'https://trunk.ascentiamd.com',
  AuthAuthorityURI: 'https://trunkauth.ascentiamd.com',
  RedirectURI: 'https://trunk.ascentiamd.com/assets/signin-callback.html',
  SilentRedirectURI: 'https://trunk.ascentiamd.com/assets/silent-callback.html',
  ApolloAPI: 'https://trunkapi.ascentiamd.com',
  CmmsCuresAPI: 'https://chartmakerapi-trunk.azurewebsites.net',
  PublicStorage: 'https://stiapollostoragetrunk.blob.core.windows.net/public',
  Prompt: 'login',
  ExpireInactiveUser: 600000,
  Idle: {
    // This has a minimum value of 1000ms as it is the minimum background interval for chrome and safari.
    // This is the time between polling intervals, where we check for idle states, in milliseconds.
    pollingIntervalMS: 1000,
    // The number of milliseconds to wait before initiating the timeout overlay.
    msUntilTimeoutCounter: 1200000,
    // The number of milliseconds to wait after the timeout overlay that the user is logged out.
    msUntilLogoutFromTimeoutCounter: 60000,
  },
  EntityLockConfiguration: {
    pushIntervalMillis: 15000,
    garbageCollectIntervalMillis: 60000 * 2,
    lockExpirationMillis: 60000,
  },
  startupTimeoutMilliseconds: 30000,
  Reporting: {
    ReportServerURI: 'https://trunkreport.ascentiamd.com/ReportServer',
    SameSite: true,
  },
};
