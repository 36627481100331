import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
})
export class PluralPipe implements PipeTransform {
  transform(value: string): string {
    if (value.endsWith('y')) {
      return `${value.substring(0, value.length - 1)}ies`;
    } else if (value.endsWith('s')) {
      if (value.endsWith('is')) {
        return `${value.substring(0, value.length - 2)}es`;
      } else {
        return `${value}es`;
      }
    } else {
      return `${value}s`;
    }
  }
}
