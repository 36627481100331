import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './modules/core/core.module';

import { AppComponent } from './app.component';

import { DateHttpInterceptor } from '@interceptors/date-response.interceptor';
import { ErrorHttpInterceptor } from '@interceptors/error.interceptor';
import { TokenHttpInterceptor } from '@interceptors/token.interceptor';

import { StartupService } from '@services/startup.service';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [AppRoutingModule, BrowserModule, BrowserAnimationsModule, CoreModule.forRoot()], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (startupService: StartupService) => () => startupService.bootstrap(),
            deps: [StartupService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DateHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
