import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IconType } from '@models/icon/icon.model';

export interface DialogButton {
  label: string;
  dialogCloseResult: string;
}

export interface DialogData {
  title?: string;
  message?: string;
  items?: string[];
  icon?: IconType;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  confirmationButtonText?: string;
  cancelButtonText?: string;
  confirmationInputLabel?: string;
  confirmationInputPlaceholder?: string;
  confirmationInputMatch?: string[];
  additionalButtons?: DialogButton[];
  messages?: DialogData[];
  panelClass?: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  private inputValue: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  public get showConfirmationInput(): boolean {
    if (this.data.confirmationInputLabel) {
      return true;
    } else {
      return false;
    }
  }

  public get enableConfirmationButton(): boolean {
    if (this.showConfirmationInput) {
      return this.data.confirmationInputMatch
        ? this.data.confirmationInputMatch?.findIndex((p) => p == this.inputValue) >= 0
        : false;
    }

    return true;
  }

  public inputChange(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    this.inputValue = target.value;
  }
}
