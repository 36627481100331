import { Injectable } from '@angular/core';
import { first, timeout } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private authService: AuthService) {}

  public bootstrap(): Observable<unknown> {
    return new Observable((subscriber) => {
      this.authService.userChecked$.pipe(first((result: boolean) => result)).subscribe(() => {
        from(this.authService.getUser()).subscribe((user) => {
          if (!user) {
            subscriber.complete();
            return;
          }
          subscriber.complete();
        });
      });
    }).pipe(timeout(environment.startupTimeoutMilliseconds));
  }
}
