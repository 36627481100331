import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizationCallbackComponent } from '@components/callbacks/authorization-callback/authorization-callback.component';
import { AuthorizationSilentCallbackComponent } from '@components/callbacks/authorization-silent-callback/authorization-silent-callback.component';
import { HomeComponent } from '@components/home/home.component';
import { LogoutComponent } from '@components/logout/logout.component';

import { AuthGuardService } from '@services/guards/auth-guard.service';
import { EmptyRouteGuardService } from '@services/guards/empty-route-guard.service';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [EmptyRouteGuardService] },
  { path: 'authorization-callback', component: AuthorizationCallbackComponent },
  { path: 'authorization-silent-callback', component: AuthorizationSilentCallbackComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], data: { checkForInitialLanding: true } },
  { path: 'manuallogout', component: LogoutComponent },
  { path: 'systemlogout', component: LogoutComponent },
  { path: 'timeoutlogout', component: LogoutComponent },
  {
    path: 'sti-console',
    loadChildren: () =>
      import('src/app/modules/sti-console/sti-console-display-mode.module').then((m) => m.StiConsoleDisplayModeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'client',
    loadChildren: () =>
      import('src/app/modules/client/client-display-mode.module').then((m) => m.ClientDisplayModeModule),
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
