import { Address } from '@models/address.model';
import { PhoneNumber } from '@models/phone-number.model';
import { Npi } from '@models/npi.model';
import { typeMap } from '@models/model-mapper';

export class Facility {
  public facilityID: number = 0;
  public name: string = '';
  public clientID: number = 0;
  public ihCode: string | nil = null;
  public isInactive: boolean = false;
  public placeOfServiceCode: string | nil = null;
  public placeOfServiceDescription: string | nil = null;
  @typeMap(Address)
  public address: Address = new Address();
  @typeMap(PhoneNumber)
  public phoneNumbers: PhoneNumber[] = [];
  @typeMap(Npi)
  public npis: Npi[] = [];

  public constructor() {}
}
