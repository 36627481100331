import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

import { ValidationMessage } from '@models/validation-messages.model';

import { RegularExpressionService } from '@services/utility/regular-expression.service';

@Injectable()
export class CustomPatternValidator {
  constructor(private regularExpressionService: RegularExpressionService) {}

  public validate(
    pattern: string | RegExp,
    type: PatternErrorMessageType,
    customMessage: string | nil = null,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationMessage | null => {
      if (control.value) {
        if (pattern instanceof RegExp) {
          return pattern.test(control.value)
            ? null
            : new ValidationMessage(customMessage ?? this.customPatternValidationMessageCallback(type));
        }
        return this.regularExpressionService.isMatch(pattern, control.value)
          ? null
          : new ValidationMessage(customMessage ?? this.customPatternValidationMessageCallback(type));
      } else return null;
    };
  }

  private customPatternValidationMessageCallback(type: PatternErrorMessageType): string {
    switch (type) {
      case PatternErrorMessageType.characters:
        return 'Invalid character';
      case PatternErrorMessageType.format:
        return 'Incorrect format';
      case PatternErrorMessageType.value:
        return 'Incorrect value';
    }
  }
}

export enum PatternErrorMessageType {
  characters,
  format,
  value,
}
