import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Address,
  CityStateLookup,
  CountryStateView,
  StateProvince,
  StateProvinceCountyView,
} from '@models/address.model';

import { ApiAccessService } from '@services/api-access.service';
import { EqualityService } from '@services/utility/equality.service';

@Injectable()
export class AddressService {
  private addressController: string = 'address';
  private _countrySelections: CountryStateView[] = [];
  private _countryOptions: any[] = [];
  private _stateProvinceSelections: StateProvinceCountyView[] = [];

  constructor(
    private apiService: ApiAccessService,
    private equalityService: EqualityService,
  ) {}

  public get countries(): any[] {
    return this._countryOptions;
  }

  //#region HTTP

  public cityStateLookup(postalCode: string): Observable<CityStateLookup> {
    let map: Map<string, string> = new Map();
    let postalCodeLocal = postalCode;

    if (postalCodeLocal.length > 5) {
      postalCodeLocal = postalCodeLocal.substr(0, 5);
    }

    map.set('zip5', postalCodeLocal);

    return this.apiService.post(this.addressController, 'CityStateLookup', map);
  }

  public getStateProvincesFromCountry(countryID: number): StateProvince[] {
    const countryView: CountryStateView | nil = this._countrySelections.find((c) => c.country?.countryID == countryID);

    return countryView?.stateProvinces.map((stateProvinceView) => stateProvinceView.stateProvince!) ?? [];
  }

  public getCountries(): Observable<any> {
    return this.apiService.get(this.addressController, 'GetCountrySelections', null, true, CountryStateView).pipe(
      map((result) => {
        this._countrySelections = result as CountryStateView[];

        this._countryOptions = [];
        this._stateProvinceSelections = [];
        for (let i = 0; i < this._countrySelections.length; i++) {
          this._countryOptions.push(this._countrySelections[i].country);

          for (let x = 0; x < this._countrySelections[i].stateProvinces.length; x++) {
            this._stateProvinceSelections.push(this._countrySelections[i].stateProvinces[x]);
          }
        }

        return this._countrySelections;
      }),
    );
  }

  public zipCodeLookup(address1: string, address2: string, city: string, stateCode: string): Observable<any> {
    let map: Map<string, string> = new Map();

    map.set('address1', address1);
    map.set('address2', address2);
    map.set('city', city);
    map.set('state', stateCode);

    return this.apiService.post(this.addressController, 'ZipCodeLookup', map);
  }

  //#endregion HTTP

  //#region Utility

  public isNewAddress(originalAddress: Address, newAddress: Address): boolean {
    return !this.equalityService.isEqual(originalAddress, newAddress);
  }

  //#endregion Utility
}
