import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarAnnotatedComponent } from '@components/snack-bar-annotated/snack-bar-annotated.component';

import { IconType } from '@models/icon/icon.model';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  public open(message: string, action: string = '', duration: number = 5): void {
    this.snackbar.open(message, action, { duration: duration * 1000 });
  }

  public openFromComponent(
    message: string,
    iconType: IconType = 'exclamationCircle',
    color: ThemePalette | nil = 'warn',
    duration: number = 5,
  ): void {
    this.snackbar.openFromComponent(SnackBarAnnotatedComponent, {
      data: { message: message, iconType: iconType, color: color },
      duration: duration * 1000,
    });
  }
}
