import { Injectable, OnDestroy } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Subscription, from } from 'rxjs';

import { environment } from '@environments/environment';

import { EntityType } from '@enums/entity-type.enum';

import { EntityLock } from '@models/entity-lock/entity-lock.model';
import { EntityLockWrapper } from '@models/entity-lock/entity-lock-wrapper.model';

import { PluralPipe } from '@pipes/plural.pipe';

import { AuthService } from '@services/auth.service';
import { ClientConnectionService } from '@services/client-connection.service';
import { SignalRService } from '@services/signal-r.service';

export interface EntityLockSlim {
  name: string;
  entityType: EntityType;
  entityID: number;
}

@Injectable()
export class EntityLockService implements OnDestroy {
  private readonly subscription: Subscription = new Subscription();
  private readonly lockExpirationMillis: number = environment.EntityLockConfiguration.lockExpirationMillis;
  private readonly garbageCollectIntervalMillis: number =
    environment.EntityLockConfiguration.garbageCollectIntervalMillis;

  private selfLocks: EntityLock[];
  private globalLocks: EntityLockWrapper[];
  private pushInterval: NodeJS.Timeout | nil;
  private garbageCollectInterval: NodeJS.Timeout;

  constructor(
    private authService: AuthService,
    private clientConnectionService: ClientConnectionService,
    private pluralPipe: PluralPipe,
    private signalRService: SignalRService,
    private titleCasePipe: TitleCasePipe,
  ) {
    this.selfLocks = [];
    this.globalLocks = [];
    this.garbageCollectInterval = setInterval(() => this.garbageCollect(), this.garbageCollectIntervalMillis);
    this.subscription.add(
      this.signalRService.entityLockPull$.subscribe((pull: EntityLockWrapper) => this.syncWithPull(pull)),
    );
    this.subscription.add(
      this.signalRService.entityLockDisconnected$.subscribe((connectionID: string) =>
        this.removeConnectionIDLocks(connectionID),
      ),
    );
    this.subscription.add(this.signalRService.reconnected$.subscribe((connectionID: string) => this.sendPush()));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.garbageCollectInterval);
    this.selfLocks = [];
    this.globalLocks = [];
    this.sendPush(false);

    if (this.pushInterval) {
      clearInterval(this.pushInterval);
    }
  }

  public addLock(entityType: EntityType, entityID: number, allowMultiple: boolean = false): void {
    let index: number = this.selfLocks.findIndex((lock) => lock.entityType == entityType && lock.entityID == entityID);

    if (index > -1 && !allowMultiple) {
      return;
    }

    this.selfLocks.push({
      entityType: entityType,
      entityID: entityID,
    });
    this.sendPush();
  }

  public removeLock(entityType: EntityType, entityID: number): void {
    let index: number = this.selfLocks.findIndex((lock) => lock.entityType == entityType && lock.entityID == entityID);

    if (index == -1) {
      return;
    }

    this.selfLocks.splice(index, 1);
    this.sendPush();
  }

  public removeAllLocks(): void {
    this.selfLocks = [];
    this.sendPush();
  }

  public getLocks(entityType: EntityType, entityID: number): EntityLockSlim[] {
    const currentUserID: string = this.authService.getSubjectID();

    return this.globalLocks
      .filter(
        (pull) =>
          pull.userID != currentUserID &&
          pull.timestamp < Date.now() + this.lockExpirationMillis &&
          pull.locks.findIndex((lock) => lock.entityType == entityType && lock.entityID == entityID) > -1,
      )
      .map(
        (pull) =>
          <EntityLockSlim>{
            name: pull.name,
            entityType: entityType,
            entityID: entityID,
          },
      );
  }

  public getAllLocks(entityType: EntityType, entityIDs: number[]): EntityLockSlim[] {
    return entityIDs
      .map((value: number) => {
        return this.getLocks(entityType, value);
      })
      .reduce((accumulator, currentValue) => {
        currentValue.forEach((value: EntityLockSlim) => {
          if (!accumulator.includes(value)) {
            accumulator.push(value);
          }
        });
        return accumulator;
      }, []);
  }

  public getUsersWhoHaveLock(entityType: EntityType, entityID: number | number[]): string[] {
    const inputIDs: number[] = Array.isArray(entityID) ? entityID : [entityID];
    const locks: EntityLockSlim[] = this.getAllLocks(entityType, inputIDs);
    const userNames: string[] = locks
      .map((lock) => lock.name)
      .filter((value: string, index: number, self: string[]) => {
        return self.indexOf(value) === index;
      });

    return userNames;
  }

  /** Returns a message indicating which users have a lock on a particular entity. If there are users with a lock, the method constructs a lock message that includes the entity name, whether the entity is singular or plural,
   *  and the list of users who have a lock on the entity. If there is additional information provided, it is included in the message. If no users have a lock, an empty string is returned
   *  @param type the type of the entity (e.g., 'user' or 'project').
   *  @param ids a single ID or an array of IDs of the entities being checked.
   *  @param additionalInfo an optional string with additional information to include in the message
   * */
  public getLockMessage(type: EntityType, ids: number | number[], additionalInfo?: string): string {
    const inputIDs: number[] = Array.isArray(ids) ? ids : [ids];
    const usersWithLock: string[] = this.getUsersWhoHaveLock(type, ids);
    let entityName: string = type;
    if (inputIDs.length > 1) {
      entityName = this.pluralPipe.transform(entityName);
    }

    return usersWithLock.length > 0
      ? `${
          additionalInfo
            ? additionalInfo + ' ' + entityName.toLowerCase()
            : this.titleCasePipe.transform(entityName.toLowerCase())
        } ${inputIDs.length > 1 ? 'are' : 'is'} currently opened by ${usersWithLock.join(', ')}`
      : '';
  }

  private sendPush(sendEmptyLocks: boolean = true, restartPushInterval: boolean = true): void {
    if ((sendEmptyLocks || this.selfLocks.length > 0) && this.signalRService.isConnected) {
      let push: EntityLockWrapper = {
        connectionID: this.signalRService.connectionID ?? '',
        clientID: this.clientConnectionService.getClientID(),
        userID: this.authService.getSubjectID(),
        timestamp: Date.now(),
        name: `${this.authService.getUserFirstName()} ${this.authService.getUserLastName()}${
          this.authService.isStiUser() ? ' (STIUser)' : ''
        }`,
        locks: this.selfLocks,
      };

      from(this.signalRService.send('EntityLockPush', push)).subscribe();
    }
  }

  private removeConnectionIDLocks(connectionID: string): void {
    let index: number = this.globalLocks.findIndex((x) => x.connectionID == connectionID);

    if (index == -1) {
      return;
    }

    this.globalLocks.splice(index, 1);
  }

  private syncWithPull(pull: EntityLockWrapper): void {
    let index: number = this.globalLocks.findIndex((x) => x.connectionID == pull.connectionID);

    if (index > -1) {
      this.globalLocks.splice(index, 1, pull);
    } else {
      this.globalLocks.push(pull);
    }
  }

  private garbageCollect(): void {
    for (let i = this.globalLocks.length - 1; i > -1; i--) {
      let pull: EntityLockWrapper = this.globalLocks[i];

      if (pull.timestamp + this.lockExpirationMillis < Date.now()) {
        this.globalLocks.splice(i, 1);
      }
    }
  }
}
