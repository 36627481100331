import { Injectable } from '@angular/core';

@Injectable()
export class RegularExpressionService {
  public readonly alphanumericPattern: string = '^[a-zA-Z0-9]*$';
  public readonly alphaNumericSpecialNoSpacesPattern: RegExp = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\|/-]*$/;
  public readonly dateRegex: string =
    '^\\d{8}\\b|\\d{2}\\/\\d{2}\\/\\d{4}\\b|\\d{1}\\/\\d{1}\\/\\d{4}\\b|\\d{2}\\/\\d{1}\\/\\d{4}\\b|\\d{1}\\/\\d{2}\\/\\d{4}\\b$';
  public readonly urlPattern: string =
    '^((https:\\/\\/)|(http:\\/\\/))?[0-9a-zA-Z]([0-9a-zA-Z-_~\\.]+[0-9a-zA-Z])?\\.[0-9a-zA-Z]([0-9a-zA-Z-_~\\.\\/]+[0-9a-zA-Z])?$';
  public readonly uriPattern: string =
    "([A-Za-z][A-Za-z0-9+\\-.]*):(?:(//)(?:((?:[A-Za-z0-9\\-._~!$&'()*+,;=:]|%[0-9A-Fa-f]{2})*)@)?((?:\\[(?:(?:(?:(?:[0-9A-Fa-f]{1,4}:){6}|::(?:[0-9A-Fa-f]{1,4}:){5}|(?:[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){4}|(?:(?:[0-9A-Fa-f]{1,4}:){0,1}[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){3}|(?:(?:[0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){2}|(?:(?:[0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4})?::[0-9A-Fa-f]{1,4}:|(?:(?:[0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4})?::)(?:[0-9A-Fa-f]{1,4}:[0-9A-Fa-f]{1,4}|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(?:(?:[0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})?::[0-9A-Fa-f]{1,4}|(?:(?:[0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})?::)|[Vv][0-9A-Fa-f]+\\.[A-Za-z0-9\\-._~!$&'()*+,;=:]+)\\]|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[A-Za-z0-9\\-._~!$&'()*+,;=]|%[0-9A-Fa-f]{2})*))(?::([0-9]*))?((?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)|/((?:(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})+(?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)?)|((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})+(?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)|)(?:\\?((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@/?]|%[0-9A-Fa-f]{2})*))?(?:\\#((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@/?]|%[0-9A-Fa-f]{2})*))?";

  // Demographics
  public readonly displayNamePattern: string = "[\\-a-zA-Z0-9 /',&\\.\\\\]*";
  public readonly electronicAddressRegex: string = '^\\S+[@]\\S+[.]\\S+$';
  public readonly namePattern: string = "^(\\s)*[A-Za-z0-9]+((\\s)?((\\'|\\-|\\’)?([A-Za-z0-9])+))*(\\s)*$";
  public readonly nameMaskedPattern: string = "^(\\s)*[(][A-Za-z0-9]+((\\s)?((\\'|\\-|\\’)?([A-Za-z0-9])+))*[)](\\s)*$";
  public readonly nameNoSpacePattern: string = "^[A-Za-z0-9]+(((\\'|\\-|\\’)?([A-Za-z0-9])+))*$";
  public readonly emailPattern: string = '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$';
  public readonly dateTimePattern: string =
    '\\d{2}[/]{1}\\d{2}[/]{1}\\d{4}[ ]{1}\\d{1,2}[:]{1}\\d{2}[ ]{1}[AP]{1}[M]{1}';
  public readonly phoneNumberRegex: string = '^(([(]([0]|[2-9])\\d{2}[)])|(\\d{3}))([ ]|[-])?\\d{3}([ ]|[-])?\\d{4}';
  public readonly ssnRegex: string = '^((\\d{3}-\\d{2}-\\d{4}))$';
  public readonly taxIdRegex = '^((\\d{2}-\\d{7})|(\\d{3}-\\d{2}-\\d{4}))$';
  public readonly npiRegex: string = '^(\\d{10}|\\d{15})';

  // File types
  public readonly csvPattern: string = '.+\\.[cC][sS][vV]';
  public readonly txtPattern: string = '.+\\.[tT][xX][tT]';
  public readonly xmlPattern: string = '.+\\.[xX][mM][lL]';
  public readonly zipPattern: string = '.+\\.[zz][iI][pP]';

  constructor() {}

  /** Determines if 'text' matches RegEx pattern*/
  public isMatch(pattern: string, text: string): boolean {
    if (pattern && text) {
      let match: RegExpMatchArray | nil = text.match(pattern);

      if (match && match.length) {
        return true;
      }
    }

    return false;
  }

  /** Returns 'RegExpMatchArray' object if text matches RegEx pattern */
  public getMatch(pattern: string, text: string): RegExpMatchArray | nil {
    if (pattern && text) {
      let match: RegExpMatchArray | nil = text.match(pattern);

      if (match && match.length) {
        return match;
      }
    }

    return null;
  }
}
