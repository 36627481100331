export function propertyMap(sourceProperty: string) {
  return function (target: any, propertyKey: string) {
    if (!target.constructor._propertyMap) {
      target.constructor._propertyMap = {};
    }
    target.constructor._propertyMap[propertyKey] = sourceProperty;
  };
}

export function typeMap<T>(type: any) {
  return function (target: any, propertyKey: any) {
    if (!target.constructor._typeMap) {
      target.constructor._typeMap = {};
    }
    target.constructor._typeMap[propertyKey] = type;
  };
}

export class ModelMapper<T> {
  _propertyMapping: any;
  _target: any;
  _typeMapping: any;

  constructor(type: { new (): T }) {
    this._target = new type();

    this._propertyMapping = this._target.constructor._propertyMap;
    this._typeMapping = this._target.constructor._typeMap;

    // Cast function to any to access .constructor
    let externalTypeMap: any = type;

    // Add typeMap(s) set outside class definition
    if (externalTypeMap.constructor._typeMap) {
      if (!this._typeMapping) {
        this._typeMapping = {};
      }
      Object.keys(externalTypeMap.constructor._typeMap).forEach((key) => {
        this._typeMapping[key] = externalTypeMap.constructor._typeMap[key];
      });
    }
  }

  public map(source: any): any {
    if (source) {
      // Target
      Object.keys(this._target).forEach((key) => {
        this.mapTarget(source, key);
      });

      // Source
      Object.keys(source).forEach((key) => {
        this.mapSource(source, key);
      });

      return this._target;
    } else {
      return source;
    }
  }

  private mapSource(source: any, key: string): void {
    const targetKeys = Object.keys(this._target);
    if (targetKeys.indexOf(key) === -1) {
      this._target[key] = source[key];
    }
  }

  private mapTarget(source: any, key: string): void {
    // Property Key
    const mappedKey = this._propertyMapping ? this._propertyMapping[key] : undefined;
    // Property Type
    const mappedType = this._typeMapping ? this._typeMapping[key] : undefined;

    // Set property, keep original, or remove to mimic JSON object
    if (source[key] != undefined) this.setTarget(source, key, mappedKey, mappedType);
    else if (this._target[key] != undefined && !Array.isArray(this._target[key])) this._target[key] = this._target[key];
    else delete this._target[key];
  }

  private mapToType(source: any, key: string, type: any): any {
    if (Array.isArray(this._target[key]))
      return source[key].map((x: any) => {
        return new ModelMapper(type).map(x);
      });
    else return new ModelMapper(type).map(source[key]);
  }

  private setTarget(source: any, key: string, mappedKey: string, mappedType: any): void {
    // Map based on KEY and/or TYPE
    if (mappedKey) this._target[key] = mappedType ? this.mapToType(source, mappedKey, mappedType) : source[mappedKey];
    else if (mappedType) this._target[key] = this.mapToType(source, key, mappedType);
    else {
      if (!Array.isArray(this._target[key]) && this._target[key] && typeof this._target[key] == 'object')
        this._target[key] =
          this._target[key] instanceof Date ? source[key] : this.mapToType(source, key, this._target[key].constructor);
      else this._target[key] = source[key];
    }
  }
}
