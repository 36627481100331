import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { UserManager, UserManagerSettings, User } from 'oidc-client';

import { environment } from '@environments/environment';

import { SessionContextService } from '@services/session-context.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly userChecked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly userLoaded: BehaviorSubject<User | nil> = new BehaviorSubject<User | nil>(null);
  private readonly userLogout: Subject<void> = new Subject<void>();

  public readonly userChecked$: Observable<boolean> = this.userChecked.asObservable();
  public readonly userLoaded$: Observable<User | nil> = this.userLoaded.asObservable();
  public readonly userLogout$: Observable<void> = this.userLogout.asObservable();
  protected subscriptions: Subscription = new Subscription();

  private manager = new UserManager(getClientSettings());
  private user: User | nil;

  constructor(
    private router: Router,
    private sessionCtx: SessionContextService,
    private dialog: MatDialog,
  ) {
    this.initUserManager();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private initUserManager() {
    this.manager.getUser().then((user) => {
      this.user = user;

      if (user) {
        if (user.expired) {
          user = null;
        } else {
          this.setToken().then(() => {
            if (user) {
              this.userChecked.next(true);
              this.userChecked.complete();
              this.userLoaded.next(user);
            }
          });
        }
      } else {
        this.userChecked.next(true);
        this.userChecked.complete();
      }
    });

    this.manager.events.addUserLoaded((user) => {
      this.manager.getUser().then((user) => {
        this.user = user;
        this.setToken().then(() => {
          this.userLoaded.next(user ?? null);
        });
      });
    });

    this.manager.events.addUserSignedOut(() => {
      this.dialog.closeAll();

      this.manager.removeUser();
      this.user = null;
      this.router.navigateByUrl('systemlogout');
      this.userLogout.next();
    });

    this.manager.events.addSilentRenewError((error) => {
      console.log(error);
    });

    this.manager.events.addAccessTokenExpiring((result) => {
      this.beginSilentAuthentication();
    });
  }

  public isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  public isExpired(): boolean {
    return this.user ? this.user.expired : true;
  }

  public isStiUser(): boolean {
    return this.userIsType('stimaster') || this.userIsType('stistandard');
  }

  public isClientUser(): boolean {
    let type = this.getUserType();
    return type.startsWith('client');
  }

  public isClientOrStiUser(): boolean {
    return this.isStiUser() || this.isClientUser();
  }

  public logOut(idle: boolean = false, system: boolean = false) {
    this.dialog.closeAll();

    let method = '/manuallogout';
    if (idle) {
      method = '/timeoutlogout';
    } else if (system) {
      method = '/systemlogout';
    }

    let args = { post_logout_redirect_uri: environment.BaseURI + method };

    this.manager.signoutRedirect(args).then(() => {
      this.manager.removeUser();
      this.user = null;
      this.manager.clearStaleState();
    });

    this.userLogout.next();
  }

  public getClaim(): any {
    return this.user?.profile;
  }

  public getUserName(): string {
    var username: string = '';

    if (this.user?.profile.name) {
      username = this.user.profile.name;
    }

    return username;
  }

  public getSubjectID(): string {
    let sub: string = '';
    if (this.user) sub = this.user.profile.sub;

    return sub;
  }

  public getUserFirstName(): string {
    let firstName: string = '';

    if (this.user?.profile.given_name) {
      firstName = this.user.profile.given_name;
    }

    return firstName;
  }

  public getUserFullName(): string {
    return `${this.getUserFirstName()} ${this.getUserLastName()}`;
  }

  public getUserLastName(): string {
    let lastName: string = '';

    if (this.user?.profile.family_name) {
      lastName = this.user.profile.family_name;
    }

    return lastName;
  }

  public getUserEmail(): string {
    if (this.user?.profile.email) {
      return this.user.profile.email;
    }

    return '';
  }

  public getToken(): Promise<string> {
    return this.manager.getUser().then((user) => user?.access_token ?? '');
  }

  public getUserType(): string {
    if (this.user != null) {
      var type: string = this.user.profile['apollo.type'];
      return type;
    }
    return '';
  }

  public userIsType(type: string): boolean {
    if (this.user != null) {
      var userType: string = this.getUserType();

      if (userType == type) return true;
    }
    return false;
  }

  public startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  public completeAuthentication(): Promise<void> {
    return this.manager.getUser().then((user) => {
      this.user = user;
      this.setToken();
    });
  }

  public beginSilentAuthentication() {
    this.manager
      .signinSilent({ scope: 'openid profile email apollo.api stiApi.api apollo.profile', response_type: 'code' })
      .then((user) => {})
      .catch((error: Error) => {
        console.log('...signinSilent error');
        console.log(error);
        this.completeAuthentication();
      });
  }

  public completeSilentAuthentication(): Promise<User | undefined> {
    return this.manager.signinSilentCallback().then((user) => {
      return this.setToken().then(() => user);
    });
  }

  public getUser(): Promise<User | null> {
    return this.manager.getUser();
  }

  private setToken(): Promise<string> {
    return this.getToken().then((token: string) => {
      this.sessionCtx.accessToken = token;
      return token;
    });
  }
}

export function getClientSettings(): UserManagerSettings {
  return {
    automaticSilentRenew: false,
    includeIdTokenInSilentRenew: true,
    authority: environment.AuthAuthorityURI,
    client_id: 'apolloWebClient',
    loadUserInfo: true,
    redirect_uri: environment.RedirectURI,
    silent_redirect_uri: environment.SilentRedirectURI,
    response_type: 'code',
    scope: 'openid profile email apollo.api stiApi.api apollo.profile',
    accessTokenExpiringNotificationTime: 120,
  };
}
