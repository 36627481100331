import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-authorization-silent-callback',
  templateUrl: './authorization-silent-callback.component.html',
})
export class AuthorizationSilentCallbackComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.completeSilentAuthentication();
  }
}
