import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, tap, BehaviorSubject, from, switchMap, EMPTY } from 'rxjs';

import { environment } from '@environments/environment';

import { ClientPracticeView } from '@models/client/client.model';
import { Practice } from '@models/practice.model';

import { ApiAccessService } from '@services/api-access.service';
import { SignalRService } from '@services/signal-r.service';

@Injectable({
  providedIn: 'root',
})
export class ClientConnectionService {
  private clientSubject: BehaviorSubject<ClientPracticeView | nil> = new BehaviorSubject<ClientPracticeView | nil>(null);
  private selectedPracticesSubject: BehaviorSubject<Practice[]> = new BehaviorSubject<Practice[]>([]);

  public client$: Observable<ClientPracticeView | nil> = this.clientSubject.asObservable();
  public clientIDStorage: string = 'apollo.clientID';
  public selectedPractices$: Observable<Practice[]> = this.selectedPracticesSubject.asObservable();

  constructor(
    private apiAccessService: ApiAccessService,
    private router: Router,
    private signalRService: SignalRService,
  ) {
  }

  //#region Get/Set

  public get client(): ClientPracticeView | nil {
    return this.clientSubject.getValue();
  }

  public set client(value: ClientPracticeView | nil) {
    this.clientSubject.next(value);
  }

  public get clientID(): number {
    return this.client ? this.client.clientID : 0;
  }

  public get selectedPractices(): Practice[] {
    return this.selectedPracticesSubject.getValue();
  }

  public set selectedPractices(value: Practice[]) {
    this.selectedPracticesSubject.next(value);
  }

  //#endregion

  //#region Connect

  public isConnected(): boolean {
    return this.client != null && this.client != undefined;
  }

  public hasClientIDInStorage(): boolean {
    if (sessionStorage.getItem(this.clientIDStorage)?.length)
      return true;
    else
      return false;
  }
  /** Alert API to connection, then open new tab with URL including parameters for 'clientID' and 'practiceIDs'.
   *
   * @param clientID ID of 'Client' entity to open
   * @param practiceIDs List of GUIDs for associated 'Practice' entities to be selected when tab opens
   */
  public connect(clientID: number, practiceIDs: string[] = [], outlet: string = 'client/dashboard'): Observable<any> {
    const map = new Map();
    map.set('clientID', clientID);

    return this.apiAccessService.post('client', 'connect', map).pipe(
      tap(() => {
        const urlTree: UrlTree = this.router.createUrlTree([outlet], {
          queryParams: {
            clientID: clientID.toString(),
            practiceIDs: practiceIDs.length ? practiceIDs.join(',') : null,
          },
        });
        const url: string = this.router.serializeUrl(urlTree);
        window.open(`${environment.BaseURI}${url}`, '_blank');
      }),
    );
  }

  /** Remove 'id' from session storage, alert API to disconnect, and (conditionally) close current window.
   *
   * @param redirect Dictates whether window closes after services are closed
   * @returns
   */
  public disconnect(redirect: boolean = false): Observable<any> {
    sessionStorage.removeItem(this.clientIDStorage);

    if (this.isConnected()) {
      let map = new Map();

      return this.apiAccessService.post('client', 'disconnect', map).pipe(
        switchMap(() => {
          return this.destroySignalRListeners().pipe(
            tap(() => {
              this.resetService();

              if (redirect) {
                window.close();
              }
            }),
          );
        }),
      );
    }

    return EMPTY;
  }

  //#endregion

  public getClient(clientID: number): Observable<ClientPracticeView> {
    return this.apiAccessService.get('client', 'GetClientForUser', clientID.toString(), false, ClientPracticeView);
  }

  public resetService(): void {
    this.client = null;
  }

  public getClientID(): number {
    return this.clientID;
  }

  private destroySignalRListeners(): Observable<any> {
    return from(
      this.signalRService.send('disconnectFromClient', this.clientID).then(() => {
        this.signalRService.disconnect();
      }),
    );
  }
}
