import { typeMap } from '../model-mapper';

export class UserRole {
  public userRoleID: number | nil;
  public role: string | nil;
  public isSystem: boolean | nil;
  public permissionLevels: UserPermissionLevel[] = [];
}

export class PermissionLevelOption {
  description: string | nil;
  value: number | nil;
}

export class UserPermission {
  permission: string | nil;
  userPermissionID: number | nil;
  @typeMap(PermissionLevelOption)
  levels: PermissionLevelOption[] = [];
}

export class UserPermissionLevel {
  public userPermissionID: number;
  public permission: string | nil;
  public level: number;

  constructor(id: number, level: number) {
    this.userPermissionID = id;
    this.level = level;
  }
}

export class UserSecurity {
  public permissions: UserPermissionLevel[] = [];
  public roleSelections: UserRole[] = [];

  public practiceSelections: number[] = [];
  public clientSelections: number[] = [];

  public securityToken: string | nil;
}

export class UserRoleSetting {
  public userRole: UserRole | nil;
  public userCount: number | nil;
}

export class PermissionCategory {
  title: string | nil;
  @typeMap(UserPermission)
  permissions: UserPermission[] = [];
  category: number = 0;
}
