import { Client } from './client/client.model';

export class Shard {
  public shardID: number = -1;
  public databaseName: string | nil;
  public serverName: string = 'server';
  public isAvailable: boolean = true;
  public shardType: string | nil;
  public shardTypeID: number = 0;
  public connection: string | nil;
  public username: string | nil;
  public password: string | nil;
  public clients: Client[] = [];
}
