import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { ClientConnectionService } from '@services/client-connection.service';
import { UserSecurityService } from '@services/user-security.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private clientConnectionService: ClientConnectionService,
    private router: Router,
    private userSecurityService: UserSecurityService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //if user is not logged in, start the login process which will redirect to the dashboard, or the last page they were attempting to view
    if (!this.authService.isLoggedIn()) {
      localStorage.setItem('apollo.redirectUrl', state.url);
      this.authService.startAuthentication();
      return false;
    }

    if (route.data.checkForInitialLanding) {
      //find display module to route
      let routerURL = this.getRouterUrl();
      return this.router.parseUrl(routerURL);
    }

    return this.userSecurityService.loadCurrentUserPermissions();
  }

  private getRouterUrl(): string {
    let routerURL = '';

    if (this.authService.isLoggedIn()) {
      if (this.clientConnectionService.isConnected() || this.clientConnectionService.hasClientIDInStorage()) {
        routerURL = '/client';
      } else if (this.authService.isStiUser()) {
        routerURL = '/sti-console';
      } else {
        routerURL = '/clientgateway';
      }
    }
    return routerURL;
  }
}
