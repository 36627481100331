import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { NavGroup } from '@models/navigation.model';

@Injectable()
export class SubNavigationService {
  private groups: NavGroup[] = [];
  public navListChanged: Subject<NavGroup[]> = new Subject<NavGroup[]>();

  constructor() {}

  public setNavigationGroups(groups: NavGroup[]) {
    this.groups = groups;
    this.navListChanged.next(this.groups);
  }

  public clearSubNav() {
    this.groups = [];
    this.navListChanged.next(this.groups);
  }

  public showSubNav(): boolean {
    return this.groups != null && this.groups.length > 0;
  }

  public getGroups(): NavGroup[] {
    return this.groups;
  }
}
