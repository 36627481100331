export class Address {
  public addressID: number = 0;
  public line1: string | nil;
  public line2: string | nil;
  public city: string | nil;
  public postalCode: string | nil;
  public stateProvinceCode: string | nil;
  public stateProvinceName: string | nil;
  public countryCode: string = 'US';
  public countryName: string = 'USA';
  public countyID?: number | nil;

  public constructor() {}

  public copyFromForm(value: any) {
    if (value) {
      this.line1 = value.line1;
      this.line2 = value.line2;
      this.city = value.city;
      this.postalCode = value.postalCode;
      this.stateProvinceCode = value.stateProvince?.code;
      this.stateProvinceName = value.stateProvince?.name;
      this.countryCode = value.country?.code;
      this.countryName = value.country?.name;
    }
  }
}

export class Country {
  countryID: number = 0;
  name: string = '';
  code: string = '';
}

export class StateProvince {
  stateProvinceID: number = 0;
  name: string = '';
  code: string = '';
  countryID: number = 0;
  countyLabel: string = '';
}

export class County {
  countyID: number = 0;
  name: string = '';
  stateProvinceID: number = 0;
}

export class StateProvinceCountyView {
  stateProvince: StateProvince | nil = null;
  counties: County[] = [];
}

export class CountryStateView {
  country: Country | nil = null;
  stateProvinces: StateProvinceCountyView[] = [];
}

export class ZipCodeLookup {
  zip5: string = '';
  zip4: string = '';
}

export class CityStateLookup {
  city: string = '';
  state: string = '';
}
