export class PaginatedSearch {
  page: number | nil;
  totalPages: number | nil;
  hasNextPage: boolean | nil;
  hasPreviousPage: boolean | nil;
  totalResultsCount: number | nil;
  results: any[] = [];
}

export class AnalyticsPaginatedSearch<T> extends PaginatedSearch {
  override results: T[] = [];
}