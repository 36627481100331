import { Component } from '@angular/core';

import { IconBase } from '@models/icon/icon.model';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent extends IconBase {}
