import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription, switchMap, tap } from 'rxjs';

import { SignalREventType } from '@models/signal-r/signal-r-notification.model';
import { UserNotification } from '@models/signal-r/user/user-notification.model';

import { AuthService } from '@services/auth.service';
import { SessionContextService } from '@services/session-context.service';
import { SignalRService } from '@services/signal-r.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  private subscription: Subscription = new Subscription();

  public isLoading: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private sessionContextService: SessionContextService,
    private signalRService: SignalRService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.isLoading = true;
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.isLoading = false;
            break;
          }
          default: {
            break;
          }
        }
      }),
    );

    this.subscription.add(
      this.authService.userLogout$.subscribe(() => {
        this.signalRService.disconnect();
      }),
    );

    this.subscription.add(this.signalRService.snackbarMessage$.subscribe());
    this.subscription.add(this.signalRService.systemNotification$.subscribe());

    this.subscription.add(
      this.signalRService.userHandler
        .onEvent$()
        .pipe(
          tap((value: any) => {
            const userNotification: UserNotification | nil = value.find(
              (value: UserNotification) => value.entityID == this.authService.getSubjectID(),
            );
            if (userNotification?.hasRoleChanges || userNotification?.eventType == SignalREventType.Delete) {
              this.authService.logOut(false, true);
            }
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.signalRService.clientHandler.onEvent$()
        .pipe(
          switchMap(() => this.sessionContextService.getAvailableClientList()),
        )
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onClick(): void {
    this.authService.startAuthentication();
  }
}
