import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-authorization-callback',
  template: '<app-loading-spinner></app-loading-spinner>',
})
export class AuthorizationCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.authService.completeAuthentication().then(() => {
      if (this.authService.isLoggedIn()) {
        this.redirectToDashboard();
      }
    });
  }

  /** Route to previous URL if we have it, otherwise go to 'Home' */
  private redirectToDashboard(): void {
    const url: string | nil = localStorage.getItem('apollo.redirectUrl');
    if (url != null && url.length > 0) {
      localStorage.removeItem('apollo.redirectUrl');
      this.router.navigate([url]);
    } else this.router.navigate(['/home']);
  }
}
