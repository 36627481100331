import { ClientPracticeView } from '@models/client/client.model';
import { UserPermissionLevel } from '@models/user/user-role.model';

export class User {
  public id: number = -1;
  public userID: string | nil;
  public username: string | nil;
  public firstname: string | nil;
  public lastname: string | nil;
  public email: string | nil;
  public type: string | nil;
  public roleIDs: number[] = [];
  public roleDisplay: string | nil;
  public isRegistered: boolean | nil;
  public isEmailConfirmed: boolean | nil;
  public isLocked: boolean | nil;
  public clientDisplay: string | nil;

  public clientPermissions: ClientPracticeView[] = [];
  public permissions: UserPermissionLevel[] = [];

  public get fullName(): string {
    return this.lastname + ', ' + this.firstname;
  }

  public get dropDownDisplay(): string {
    return this.firstname + ' ' + this.lastname + ' (' + this.username + ')';
  }

  constructor(firstname: string, lastname: string, email: string, type: string) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.type = type;
  }
}
