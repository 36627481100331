import { Address } from './address.model';
import { PhoneNumber } from './phone-number.model';
import { typeMap } from './model-mapper';

export class Person {
  public personID: number | nil;
  public firstName: string | nil;
  public middleName: string | nil;
  public lastName: string | nil;
  public suffix: string | nil;
  @typeMap(Address)
  public address: Address | nil = new Address();
  @typeMap(PhoneNumber)
  public phoneNumbers: PhoneNumber[] = [];

  public copyFromForm(nameGroupValue: any, addressGroupValue: any, phoneNumberGroupValues: any[]) {
    if (nameGroupValue) {
      this.firstName = nameGroupValue.first;
      this.middleName = nameGroupValue.middle;
      this.lastName = nameGroupValue.last;
      this.suffix = nameGroupValue.suffix;
    }

    if (!this.address) {
      this.address = new Address();
    }

    this.address.copyFromForm(addressGroupValue);

    if (!this.phoneNumbers) this.phoneNumbers = [];

    if (phoneNumberGroupValues?.length) {
      let order = 1;
      for (let i = 0; i < phoneNumberGroupValues.length; i++) {
        let pn: PhoneNumber = phoneNumberGroupValues[i];

        if (this.phoneNumbers.length < i + 1 && pn?.number) {
          //add new phone to list
          let phone = new PhoneNumber();
          phone.copyFromForm(pn);
          phone.order = order++;
          this.phoneNumbers.push(phone);
        } else if (this.phoneNumbers.length > i) {
          this.phoneNumbers[i].copyFromForm(pn);
          this.phoneNumbers[i].order = order++;
        }
      }
    }
  }
}
