import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumber } from '@models/phone-number.model';

@Pipe({
  name: 'phoneNumbersText',
  pure: false,
})
export class PhoneNumbersTextPipe implements PipeTransform {
  transform(phoneNumbers: PhoneNumber[]): string {
    let returnVal: string = '';

    if (phoneNumbers && phoneNumbers.length) {
      for (let i: number = 0; i < phoneNumbers.length; i++) {
        if (returnVal) {
          returnVal += `, `;
        }

        if (phoneNumbers[i].areaCode && phoneNumbers[i].number) {
          returnVal += `(${phoneNumbers[i].areaCode}) ${phoneNumbers[i].number?.substr(0, 3)}-${phoneNumbers[i].number?.substr(3)}`;

          if (phoneNumbers[i].extension) {
            returnVal += ` x${phoneNumbers[i].extension}`;
          }
        }
      }
    }

    return returnVal;
  }
}
