import { Injectable } from '@angular/core';

import { ModelMapper } from '@models/model-mapper';

@Injectable()
export class ModelMapperService {
  //#region Add

  public addTypeMap(targetType: any, key: string, type: any): any {
    let target: any = targetType;
    if (!target.constructor._typeMap) target.constructor._typeMap = {};
    target.constructor._typeMap[key] = type;
    return target;
  }

  public addTypeMaps(targetType: any, maps: [[string, any]]): any {
    let target: any = targetType;
    maps.forEach((map) => {
      this.addTypeMap(targetType, map[0], map[1]);
    });
    return target;
  }

  //#endregion Add

  //#region Map

  public mapResult(result: any, type: any): any {
    return Array.isArray(result)
      ? result.map((item) => {
          return new ModelMapper(type).map(item);
        })
      : new ModelMapper(type).map(result);
  }

  //#endregion Map
}
