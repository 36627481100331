import { Person } from '@models/person.model';
import { Practice } from '@models/practice.model';
import { ProviderIHCode } from '@models/provider/provider-ih-code.model';
import { TaxonomyCode } from '@models/provider/taxonomy-code.mode';
import { typeMap } from '@models/model-mapper';

export class Provider {
  public providerID: number = 0;
  public clientID: number = 0;
  @typeMap(ProviderIHCode)
  public providerIHCodes: ProviderIHCode[] = [];
  public degree: string = '';
  public npi: string = '';
  @typeMap(Person)
  public person: Person = new Person();
  @typeMap(Practice)
  public practices: Practice[] = [];
  @typeMap(TaxonomyCode)
  public taxonomyCode: TaxonomyCode | nil;

  public get ihCodes(): string[] {
    return this.providerIHCodes
      .filter((value: ProviderIHCode) => value.isActive == true)
      .map((value: ProviderIHCode) => value.ihCode);
  }

  public get activeIHCodes(): string[] {
    return this.providerIHCodes
      .filter((value: ProviderIHCode) => value.isActive == true)
      .map((value: ProviderIHCode) => value.ihCode);
  }

  public get activeIHCodesDisplay(): string {
    return this.activeIHCodes.join(', ');
  }

  public get inActiveIHCodesDisplay(): string {
    return this.providerIHCodes
      .filter((value: ProviderIHCode) => value.isActive == false)
      .map((value: ProviderIHCode) => value.ihCode)
      .join(', ');
  }

  public get displayName(): string {
    return `${this.person?.firstName} ${this.person?.lastName} ${this.ihCodes?.length ? '(' + this.ihCodes + ')' : ''}`;
  }

  public get displayNameWithNPI(): string {
    const name: string = `${ this.person?.firstName } ${ this.person?.lastName }`;
    if (this.npi) {
      return `${name} (${this.npi})`;
    }
    else
      return name;
  }
}
