import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

import { CustomPatternValidator, PatternErrorMessageType } from '@validators/custom-pattern.validator';
import { ValidationMessage } from '@models/validation-messages.model';

import { RegularExpressionService } from '@services/utility/regular-expression.service';
import { Injectable } from '@angular/core';

@Injectable()
export class NpiValidator {
  constructor(
    private customPatternValidator: CustomPatternValidator,
    private regExService: RegularExpressionService,
  ) {}

  validate(): ValidatorFn | nil {
    return Validators.compose([
      this.customPatternValidator.validate(this.regExService.npiRegex, PatternErrorMessageType.format).bind(this),
      this.npiValidator(),
    ]);
  }

  private npiValidator(): ValidatorFn {
    return (ctrl: AbstractControl): ValidationMessage | null => {
      if (!ctrl.value || ctrl.value.length == 0) return null;

      let NPI: string = ctrl.value;

      // If NPI is not in one of two lengths
      // If it is not a number, NPIs are numeric.
      if ((NPI.length != 10 && NPI.length != 15) || Number.isNaN(NPI)) {
        return new ValidationMessage('Incorrect format');
      }

      if (NPI.length == 15) {
        NPI = NPI.substring(5);
      }

      // Get the check digit.
      let checkDigit: number = parseInt(NPI.charAt(NPI.length - 1));
      let checkDigitConfirm: number = 0;
      let valueStr: string;
      let value: number = 24;
      let doubleVal: boolean = true;

      // Remove the check digit from the string.
      NPI = NPI.substring(0, NPI.length - 1);

      // Get Luhn Formula value;
      for (let i = NPI.length - 1; i > -1; i--) {
        if (doubleVal) {
          valueStr = (parseInt(NPI[i]) * 2).toString();

          for (let n = 0; n < valueStr.length; n++) {
            value += parseInt(valueStr[n]);
          }
        } else {
          value += parseInt(NPI[i]);
        }

        doubleVal = !doubleVal;
      }

      // Compute the check digit to compare.
      checkDigitConfirm = Math.ceil(value / 10.0) * 10 - value;

      // Confirm check digit.
      if (checkDigitConfirm != checkDigit) {
        return new ValidationMessage('Invalid');
      }

      return null;
    };
  }
}
