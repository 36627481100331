@switch (iconType) {
    @case ("svg") {
        <mat-icon
            [color]="color"
            [inline]="inline"
            [svgIcon]="svgIcon!"></mat-icon>
    }
    @case ("font") {
        @if (iconConfig) {
            <mat-icon
                [class.responsive]="isResponsive"
                [class.x-small]="fontSize == 'x-small'"
                [class.small]="fontSize == 'small'"
                [class.medium]="fontSize == 'medium'"
                [class.large]="fontSize == 'large'"
                [class.inherit-color]="color == null"
                [color]="color"
                [fontSet]="useAlternateStyle ? iconConfig.fontSetAlternate! : iconConfig.fontSet!"
                [inline]="inline"
                [innerHTML]="formattedUnicode"
                [matBadge]="badgeLength"
                [matBadgeColor]="badgeColor"
                [matBadgeSize]="badgeSize"
                [matBadgePosition]="badgePosition"
                [matBadgeHidden]="badgeLength == null"
                [matTooltip]="tooltip ?? ''"
                [matTooltipClass]="matTooltipClass ?? ''"
                [matTooltipPosition]="matTooltipPosition"
                aria-hidden="false">
            </mat-icon>
        }
    }
}
