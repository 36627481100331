<app-home>
    <div class="center-content">
        <div class="bold">
            {{ message }}
        </div>
        <div>
            For your security we recommend closing your browser once you're through using our website to prevent
            unauthorized access.
        </div>
    </div>
</app-home>
