import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '@models/person.model';

@Pipe({
  name: 'personName',
  pure: false,
})
export class PersonNamePipe implements PipeTransform {
  transform(
    person: Person,
    includeMiddle: boolean = true,
    includeSuffix: boolean = true,
    lastNameFirst: boolean = false,
  ): string {
    let name: string = '';

    if (person) {
      if (!lastNameFirst) {
        if (person?.firstName) name = person.firstName;

        if (person?.middleName && includeMiddle) {
          if (name.length > 0) name += ' ';
          name += person.middleName;
        }

        if (person?.lastName) {
          if (name.length > 0) name += ' ';
          name += person.lastName;
        }

        if (person?.suffix && includeSuffix) {
          if (name.length > 0) name += ', ';
          name += person.suffix;
        }
      } else {
        //LastName Suffix, FirstName MiddleName
        if (person?.lastName) {
          name = person.lastName;
        }

        if (person?.suffix && includeSuffix) {
          if (name.length > 0) name += ' ';
          name += person.suffix;
        }

        if (person?.firstName) {
          if (name.length > 0) name += ', ';
          name += person.firstName;
        }

        if (person?.middleName && includeMiddle) {
          if (name.length > 0) name += ' ';
          name += person.middleName;
        }
      }
    }

    return name;
  }
}
