import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleDefaultOptions, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';

// Components
import { ActionMenuComponent } from '@components/action-menu/action-menu.component';
import { AddressComponent } from '@components/form-controls/address/address.component';
import { AuthorizationCallbackComponent } from '@components/callbacks/authorization-callback/authorization-callback.component';
import { ChangeTrackerDialogComponent } from '@components/dialog/change-tracker-dialog/change-tracker-dialog.component';
import { ClientConnectMenuComponent } from '@components/client-connect-menu/client-connect-menu.component';
import { ClearableInputComponent } from '@components/form-controls/clearable-input/clearable-input.component';
import { ClientPracticeSelectComponent } from '@components/form-controls/client-practice-select/client-practice-select.component';
import { ClientPracticeTreeComponent } from '@components/client-practice-tree/client-practice-tree.component';
import { DateComponent } from '@components/form-controls/date/date.component';
import { DateRangeComponent } from '@components/form-controls/date-range/date-range.component';
import { DateRangeMonthComponent } from './components/form-controls/date-range-month/date-range-month.component';
import { DialogComponent } from '@components/dialog/dialog.component';
import { DropDownListComponent } from '@components/form-controls/drop-down-list/drop-down-list.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { FormSideSheetComponent } from '@components/form-side-sheet/form-side-sheet.component';
import { HomeComponent } from '@components/home/home.component';
import { IconButtonComponent } from '@components/icon/icon-button/icon-button.component';
import { IconComponent } from '@components/icon/icon/icon.component';
import { IconTextButtonComponent } from '@components/icon/icon-text-button/icon-text-button.component';
import { IdleComponent } from '@components/idle/idle.component';
import { ImagePickerComponent } from '@components/image/image-picker/image-picker.component';
import { ImageCrop } from '@components/image/image-crop/image-crop.component';
import { LoadingSpinnerComponent } from '@components/icon/loading-spinner/loading-spinner.component';
import { LogoutComponent } from '@components/logout/logout.component';
import { NameComponent } from '@components/form-controls/name/name.component';
import { PaginatedGridComponent } from '@components/paginated-grid/paginated-grid.component';
import { PaginatedGridContainerComponent } from '@components/paginated-grid-container/paginated-grid-container.component';
import { PhoneNumberComponent } from './components/form-controls/phone-number/phone-number.component';
import { ProgressIndicatorComponent } from '@components/progress-indicator/progress-indicator.component';
import { PaginatedSearchComponent } from '@components/search/paginated-search/paginated-search.component';
import { SideNavComponent } from '@components/side-nav/side-nav.component';
import { SideNavHiddenMenuComponent } from '@components/side-nav/sidenav-hidden-menu/sidenav-hidden-menu.component';
import { SideSheetComponent } from '@components/side-sheet/side-sheet.component';
import { SnackBarAnnotatedComponent } from '@components/snack-bar-annotated/snack-bar-annotated.component';
import { TitleTextComponent } from '@components/title-text/title-text.component';
import { UserSearchComponent } from '@components/search/user-search/user-search.component';
import { WatermarkIconComponent } from '@components/icon/watermark-icon/watermark-icon.component';

// Directives
import { HideParentTooltipDirective } from '@directives/hide-parent-tooltip.directive';
import { NativeElementInjectorDirective } from '@directives/native-element-injector.directive';
import { OverrideMaterialStylingDirective } from '@directives/override-material-styling.directive';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { TooltipShowIfTruncatedDirective } from '@directives/tooltip-show-if-truncated.directive';
import { TrackableDirective } from '@directives/trackable.directive';
import { VerticalScrollbarDetectedDirective } from '@directives/vertical-scrollbar-detected.directive';

// Models
import { FormErrorStateMatcher } from '@models/abstract/form-error-state-matcher.model';

// Services
import { AddressService } from '@services/address.service';
import { CloneService } from '@services/utility/clone.service';
import { DialogService } from '@services/dialog.service';
import { EntityLockService } from '@services/entity-lock.service';
import { EqualityService } from '@services/utility/equality.service';
import { FileService } from '@services/utility/file.service';
import { HealthService } from '@services/health.service';
import { IdleService } from '@services/idle.service';
import { ModelMapperService } from '@services/utility/model-mapper.service';
import { PaginatedGridService } from '@services/paginated-grid.service';
import { RegularExpressionService } from '@services/utility/regular-expression.service';
import { SortService } from '@services/utility/sort.service';
import { SearchService } from '@services/search.service';
import { SubNavigationService } from '@services/sub-navigation.service';
import { UIUtilityService } from '@services/utility/ui-utility.service';
import { ValidationMessageService } from '@services/validation-message.service';

// Pipes
import { AddressTextPipe } from '@pipes/address-text.pipe';
import { AgePipe } from '@pipes/age.pipe';
import { ClientListPipe } from '@pipes/client-list-pipe';
import { DateTimeStringPipe } from '@pipes/date-time-string.pipe';
import { PersonNamePipe } from '@pipes/person-name.pipe';
import { PhoneNumbersTextPipe } from './pipes/phone-number-text.pipe';
import { PluralPipe } from '@pipes/plural.pipe';
import { ProviderNamePipe } from '@pipes/provider-name.pipe';
import { RemovePeriodPipe } from '@pipes/remove-period.pipe';
import { TrustHtmlPipe } from '@pipes/trust-html.pipe';
import { TrustUrlPipe } from '@pipes/trust-url.pipe';
import { UppercaseFirstLetterPipe } from '@pipes/uppercase-first-letter.pipe';
import { UserTypeDisplayPipe } from '@pipes/user-access-type.pipe';

// Validators
import { CustomPatternValidator } from '@validators/custom-pattern.validator';
import { CurrencyInputComponent } from './components/form-controls/currency-input/currency-input.component';
import { NpiValidator } from '@validators/npi.validator';

@NgModule({
  declarations: [
    // Components
    ActionMenuComponent,
    AddressComponent,
    AuthorizationCallbackComponent,
    ChangeTrackerDialogComponent,
    ClearableInputComponent,
    ClientConnectMenuComponent,
    ClientPracticeSelectComponent,
    ClientPracticeTreeComponent,
    CurrencyInputComponent,
    DateComponent,
    DateRangeComponent,
    DateRangeMonthComponent,
    DialogComponent,
    DropDownListComponent,
    FilterButtonComponent,
    FormSideSheetComponent,
    HomeComponent,
    IconButtonComponent,
    IconComponent,
    IconTextButtonComponent,
    IdleComponent,
    ImagePickerComponent,
    ImageCrop,
    LoadingSpinnerComponent,
    LogoutComponent,
    NameComponent,
    PaginatedGridComponent,
    PaginatedGridContainerComponent,
    PhoneNumberComponent,
    ProgressIndicatorComponent,
    PaginatedSearchComponent,
    SideNavComponent,
    SideNavHiddenMenuComponent,
    SideSheetComponent,
    SnackBarAnnotatedComponent,
    TitleTextComponent,
    UserSearchComponent,
    WatermarkIconComponent,

    // Directives
    HideParentTooltipDirective,
    NativeElementInjectorDirective,
    OverrideMaterialStylingDirective,
    StopPropagationDirective,
    TooltipShowIfTruncatedDirective,
    TrackableDirective,
    VerticalScrollbarDetectedDirective,

    // Pipes
    AddressTextPipe,
    AgePipe,
    ClientListPipe,
    DateTimeStringPipe,
    RemovePeriodPipe,
    PersonNamePipe,
    PhoneNumbersTextPipe,
    PluralPipe,
    ProviderNamePipe,
    TrustHtmlPipe,
    TrustUrlPipe,
    UppercaseFirstLetterPipe,
    UserTypeDisplayPipe,
  ],
  imports: [
    A11yModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    ImageCropperModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ReactiveFormsModule,
    RouterModule,
    WebcamModule,
  ],
  providers: [
    // Services
    AddressService,
    CloneService,
    DialogService,
    EntityLockService,
    EqualityService,
    FileService,
    HealthService,
    IdleService,
    ModelMapperService,
    PaginatedGridService,
    RegularExpressionService,
    SearchService,
    SortService,
    SubNavigationService,
    UIUtilityService,
    ValidationMessageService,

    // Pipes
    AddressTextPipe,
    AgePipe,
    ClientListPipe,
    CurrencyPipe,
    DatePipe,
    DateTimeStringPipe,
    DecimalPipe,
    PersonNamePipe,
    PhoneNumbersTextPipe,
    PluralPipe,
    ProviderNamePipe,
    TitleCasePipe,
    UserTypeDisplayPipe,

    // Validators
    CustomPatternValidator,
    NpiValidator,

    // Global
    { provide: FormErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { hideMultipleSelectionIndicator: true, hideSingleSelectionIndicator: true } as MatButtonToggleDefaultOptions }
  ],
  exports: [
    //Components
    ActionMenuComponent,
    AddressComponent,
    AuthorizationCallbackComponent,
    ClearableInputComponent,
    ClientConnectMenuComponent,
    ClientPracticeSelectComponent,
    ClientPracticeTreeComponent,
    CurrencyInputComponent,
    DateComponent,
    DateRangeComponent,
    DateRangeMonthComponent,
    DialogComponent,
    DropDownListComponent,
    FilterButtonComponent,
    FormSideSheetComponent,
    HomeComponent,
    IconButtonComponent,
    IconComponent,
    IconTextButtonComponent,
    IdleComponent,
    ImagePickerComponent,
    ImageCrop,
    LoadingSpinnerComponent,
    LogoutComponent,
    NameComponent,
    PaginatedGridComponent,
    PaginatedGridContainerComponent,
    PhoneNumberComponent,
    ProgressIndicatorComponent,
    PaginatedSearchComponent,
    SideNavComponent,
    SideNavHiddenMenuComponent,
    SideSheetComponent,
    TitleTextComponent,
    UserSearchComponent,
    WatermarkIconComponent,

    // Directives
    HideParentTooltipDirective,
    NativeElementInjectorDirective,
    OverrideMaterialStylingDirective,
    StopPropagationDirective,
    TooltipShowIfTruncatedDirective,
    TrackableDirective,
    VerticalScrollbarDetectedDirective,

    // Pipes
    AddressTextPipe,
    AgePipe,
    ClientListPipe,
    DateTimeStringPipe,
    RemovePeriodPipe,
    PersonNamePipe,
    PhoneNumbersTextPipe,
    PluralPipe,
    ProviderNamePipe,
    TrustHtmlPipe,
    TrustUrlPipe,
    UppercaseFirstLetterPipe,
    UserTypeDisplayPipe,

    // Modules
    A11yModule,
    CommonModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
