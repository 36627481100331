import { ClientAssignment } from '@models/client/client-assignment.model';
import { Shard } from '@models/shard.model';
import { typeMap } from '@models/model-mapper';
import { Practice } from '../practice.model';

export class Client {
  public clientID: number = -1;
  public displayName: string = 'Client';
  public hdsDisplayNumber: string = ''; //the HDS number used to manage the client.
  public allowedUsers: number = 1;
  public allowedPractices: number = 1;
  public uuid: string = '';
  public isCMMSClient: boolean = true;
  public inProgress: boolean = false;
  public isQualityReportingEnabled: boolean = false;
  @typeMap(Shard)
  public shards: Shard[] = [];
  @typeMap(ClientAssignment)
  public assignments: ClientAssignment[] = [];

  public get applicationShard(): Shard | nil {
    return this.shards.find((value: Shard) => value.shardTypeID === 1);
  }

  public get auditShard(): Shard | nil {
    return this.shards.find((value: Shard) => value.shardTypeID === 2);
  }

  public get hasShard(): boolean {
    return this.applicationShard != null && this.auditShard != null;
  }

  public get dtsIDs(): string {
    //returns the DTS ID numbers from the datasources assigned to this client.
    if (this.assignments?.length > 0) {
      let hdsNumbers: string[] = [];

      this.assignments.forEach((assignment: ClientAssignment) => {
        const hds: string | nil = assignment.dataSource
          ? assignment.dataSource.hdsNumber
          : assignment.practice?.hdsNumber;
        if (hds && hdsNumbers.indexOf(hds) < 0) {
          hdsNumbers.push(hds);
        }
      });

      return hdsNumbers
        .sort((a: string, b: string) => {
          if ((a.toLowerCase() ?? '') < (b.toLowerCase() ?? '')) {
            return -1;
          } else if ((a.toLowerCase() ?? '') > (b.toLowerCase() ?? '')) {
            return 1;
          } else {
            return 0;
          }
        })
        .join(', ');
    } else return '';
  }

  public get practices(): Practice[] {
    let practices: Practice[] = [];

    if (this.assignments?.length > 0) {
      this.assignments.forEach((value: ClientAssignment) => {
        if (value.practice?.practiceGUID) {
          practices.push(value.practice);
        } else if (value.dataSource?.practices?.length) {
          value.dataSource.practices.forEach((practice: Practice) => {
            practices.push(practice);
          });
        }
      });
    }

    return practices;
  }
}

export class ClientPracticeView {
  public clientID: number = -1;
  public clientUUID: string = '';
  // This is the real hds number
  public hdsNumber: string = '';
  public displayName: string = '';
  public hasSinglePractice: boolean = true;
  @typeMap(Practice)
  public practices: Practice[] = [];
  public viewAsClient: boolean = true;
  public isCMMSClient: boolean = true;

  // These are actually dtsIds
  public get hdsNumbers(): string {
    return [...new Set(this.practices.map((practice: Practice) => practice.hdsNumber))].join(', ');
  }

  public get practiceGUIDs(): string[] {
    return this.practices.map((practice: Practice) => practice.practiceGUID);
  }
}

export class ClientPracticeSelection {
  public clientID: number;
  public clientUUID: string;
  public viewAsClient: boolean;
  public practiceGUIDs: string[];

  constructor(clientID: number, clientUUID: string, viewAsClient: boolean, practiceGUIDs: string[]) {
    this.clientID = clientID;
    this.clientUUID = clientUUID;
    this.viewAsClient = viewAsClient;
    this.practiceGUIDs = practiceGUIDs;
  }
}
