@if (!authService.isLoggedIn()) {
    <div class="login-container">
        <div class="logo-container">
            <img
                title="logo"
                src="../../assets/images/AscentiaLogo.png" />
        </div>
        <button
            type="button"
            mat-flat-button
            (click)="onClick()">
            LOGIN
        </button>
    </div>
} @else if (isLoading) {
    <mat-progress-bar mode="buffer"> </mat-progress-bar>
}
<div class="mat-app-background">
    <router-outlet></router-outlet>
</div>
