import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { DialogComponent, DialogData } from '@components/dialog/dialog.component';

import { ApiError, ApiErrorMessage, ApiErrorTypeCode } from '@models/api-error.model';

import { DialogRef, DialogService } from '@services/dialog.service';

const ERROR_MESSAGE_DIALOG_DEFAULT_DATA: DialogData = {
  title: 'Unexpected Error',
  message: 'You have encountered an unexpected error. Please contact STI Customer Support.',
};

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private dialogRef: DialogRef<DialogComponent, string> | nil = null;

  constructor(private dialogService: DialogService) {}

  public handleHttpError(error: HttpErrorResponse): Observable<never> {
    let innerError: ApiError | nil = null;
    const defaultErrorMessage: string =
      'You have encountered an unexpected error, please try again. If the problem persists, please contact STI Customer Support.';

    if (error?.error instanceof ErrorEvent) {
      innerError = new ApiError();
      innerError.messages.push({
        message: error.error.message,
        errorCode: ApiErrorTypeCode.UnhandleException,
        title: '',
        typeCode: 0,
      });
    } else if (error?.error instanceof ProgressEvent) {
      innerError = new ApiError();
      innerError.messages.push({
        message: defaultErrorMessage,
        errorCode: ApiErrorTypeCode.UnhandleException,
        title: '',
        typeCode: 0,
      });
    } else if (error?.error) innerError = error.error as ApiError;
    else {
      innerError = new ApiError();
      innerError.messages.push({
        message: defaultErrorMessage,
        errorCode: ApiErrorTypeCode.UnhandleException,
        title: '',
        typeCode: 0,
      });
    }

    if (error?.status) innerError.statusCode = error.status;

    let dialogData: DialogData[] = this.createDialogDataForError(innerError);

    if (!this.dialogRef && dialogData && dialogData.length) {
      if (dialogData.length == 1 && dialogData[0].message) {
        this.dialogRef = this.dialogService.openMessageError({
          ...ERROR_MESSAGE_DIALOG_DEFAULT_DATA,
          ...dialogData[0],
        });

        this.dialogRef.afterClosed$.subscribe(() => (this.dialogRef = null));
      } else if (dialogData.length > 1) {
        this.dialogService.openMultiMessageError(dialogData);
      }
    }

    return throwError(innerError);
  }

  private createDialogDataForError(error: ApiError): DialogData[] {
    if (error && error.messages && error.messages.length) {
      let data: DialogData[] = [];

      error.messages.forEach((errorMessage: ApiErrorMessage) => {
        if (errorMessage.typeCode == ApiErrorTypeCode.UnhandleException || errorMessage.errorCode) {
          data.push({
            title: errorMessage.title ?? 'Error',
            message: errorMessage.message ?? '',
          });
        }
      });
      return data;
    }

    return [{}];
  }
}
