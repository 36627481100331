import { Address } from './address.model';
import { typeMap } from './model-mapper';
import { PhoneNumber } from './phone-number.model';
import { TaxonomyCode } from './provider/taxonomy-code.mode';

export class Practice {
  public clientID: number = -1;

  public description: string = '';
  public practiceGUID: string = '00000000-0000-0000-0000-000000000000'; //need to default in the empty guid or this will not deserialize on the API
  @typeMap(Address)
  public address: Address | nil = null;
  public taxID: string = '';
  public taxonomyCode: TaxonomyCode | nil = null;
  public isInactive: boolean = false;
  public npi: string = '';
  @typeMap(PhoneNumber)
  public phoneNumbers: PhoneNumber[] = [];

  //PM practice related date (not applicable to shard practices)
  public hdsNumber: string = ''; // This is actually the DTS Id
  public ihCode: string = '';
  public dataSourceName: string = '';

  //if the practice info is coming from the shard
  public practiceID: number = 0;

  public get displayWithTIN(): string {
    if (this.taxID) {
      return `${this.description} (${this.taxID})`;
    }
    else
      return this.description;
  }
}
