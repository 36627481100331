import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public onClick(): void {
    window.open('https://sticomputer.com/solutions/revenue-cycle-management/');
  }
}
