export class ApiError {
  messages: ApiErrorMessage[] = [];
  statusCode: number | nil;
}

export class ApiErrorMessage {
  typeCode: number | nil;
  errorCode: number | nil;
  message: string | nil;
  title: string | nil;
}

export enum ApiErrorTypeCode {
  UnhandleException = 1,
  HandledError = 2,
  Warning = 3,
  Information = 4,
}

export enum ApiErrorCode {
  //patient errors
  PatientInactive = 100,

  //note errors
  NoteDeletion = 200,
}
