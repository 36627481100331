import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class DateHttpInterceptor implements HttpInterceptor {
  private iso8601: RegExp = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  constructor(private platform: Platform) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.convertToDate(event.body);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
            }
          }
        },
      ),
    );
  }

  private convertToDate(body: any): void {
    if (!body || typeof body !== 'object') {
      return;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (this.isIso8601(value)) {
        body[key] = new Date(value);
        if (this.platform.SAFARI) {
          body[key].setMinutes(body[key].getMinutes() + body[key].getTimezoneOffset());
        }
      } else if (typeof value === 'object') {
        this.convertToDate(value);
      }
    }
  }

  private isIso8601(value: string): boolean {
    if (!value) {
      return false;
    }

    return this.iso8601.test(value);
  }
}
