export enum ApplicationAreas {
  Unknown,
  Account,
  Configuration,
  Practice,
  System,
  Provider,
  Patient,
  Facility,
  Reporting,
  UserPreference,
  Document,
  AuditIntegrity,
  AuditReport,
}
