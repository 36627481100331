import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Practice } from '@models/practice.model';

import { ClientConnectionService } from '@services/client-connection.service';
import { SessionContextService } from '@services/session-context.service';
import { SignalRService } from '@services/signal-r.service';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  constructor(
    private clientConnectionService: ClientConnectionService,
    private sessionCtx: SessionContextService,
    private signalRService: SignalRService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.sessionCtx.accessToken}`,
        OffsetTime: '-' + new Date().getTimezoneOffset().toString(),
        SignalRConnectionID: this.signalRService.connectionID ? this.signalRService.connectionID : '',
        Permissions: JSON.stringify(this.sessionCtx.permissions),
        SecurityToken: this.sessionCtx?.securityToken,
      },
    });

    if (this.clientConnectionService.isConnected()) {
      request = request.clone({
        headers: request.headers
          .set('Client', this.clientConnectionService.clientID.toString())
          .set(
            'PracticeGUIDs',
            this.clientConnectionService.selectedPractices?.length
              ? this.clientConnectionService.selectedPractices.map((practice: Practice) => practice.practiceGUID)
              : '',
          ),
      });
    }

    return next.handle(request);
  }
}
