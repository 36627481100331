import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogData } from '@components/dialog/dialog.component';
import { Observable, map } from 'rxjs';

export interface DialogRef<T, R> {
  componentInstance: T;
  afterClosed$: Observable<R | nil>;
}

const DIALOG_DEFAULT_DATA: DialogData = {};

const DIALOG_MESSAGE_DEFAULT_DATA: DialogData = {
  confirmationButtonText: 'ok',
  hideCancel: true,
};

const DIALOG_INFORMATION_DEFAULT_DATA: DialogData = {
  icon: 'infoCircle',
};

const DIALOG_WARNING_DEFAULT_DATA: DialogData = {
  icon: 'exclamationCircle',
};

const DIALOG_ERROR_DEFAULT_DATA: DialogData = {
  icon: 'error',
};

@Injectable()
export class DialogService {
  constructor(
    private matDialog: MatDialog,
    private titleCasePipe: TitleCasePipe,
  ) {}

  /** Displays a confirmation dialog, prompting the user to confirm an action (such as deleting or updating an entity). Constructs a message based on the provided parameters and combines it with an optional additional message
   *  @param action the action to perform (e.g., 'delete' or 'update')
   *  @param entityName the name of the entity being affected (e.g., 'user' or 'project')
   *  @param items an array of affected items
   *  @param additionalMessage an optional additional message to display in the dialog
   *  @param isDelete a boolean indicating whether the action is a delete operation (default is true)
   * */
  public confirmationPrompt(
    action: string,
    entityName: string,
    items: string[],
    additionalMessage: string | nil = null,
    isDelete: boolean = true,
  ): DialogRef<DialogComponent, boolean> {
    let message: string = `You are about to ${action.toLowerCase()} ${
      items.length > 1 ? 'these' : 'this'
    } ${entityName.toLowerCase()}.`;

    if (isDelete) {
      message = `${message} By doing so you will be unable to access ${
        items.length > 1 ? 'them' : 'it'
      } again. This ${action.toLowerCase()} cannot be undone.`;
    }

    if (additionalMessage) {
      message = `${message} \n\n${additionalMessage}`;
    }

    message = `${message} \n\nAre you sure you want to continue?`;

    const dialogRef: DialogRef<DialogComponent, boolean> = this.openConfirm({
      title: this.titleCasePipe.transform(`${action} ${entityName}`),
      message: message,
      items: items,
      confirmationButtonText: action,
    });
    return dialogRef;
  }

  public openConfirm(data: DialogData = {}): DialogRef<DialogComponent, boolean> {
    const dialogRef: MatDialogRef<DialogComponent, string> = this.matDialog.open<DialogComponent, DialogData, string>(
      DialogComponent,
      {
        data: {
          ...DIALOG_DEFAULT_DATA,
          ...data,
          panelClass: data.panelClass,
        },
      },
    );

    return {
      componentInstance: dialogRef.componentInstance,
      afterClosed$: dialogRef.afterClosed().pipe(
        map((result: string | undefined) => {
          return result == 'ok';
        }),
      ),
    };
  }

  public open(data: DialogData = {}): DialogRef<DialogComponent, string> {
    const dialogRef: MatDialogRef<DialogComponent, string> = this.matDialog.open<DialogComponent, DialogData, string>(
      DialogComponent,
      {
        data: {
          ...DIALOG_DEFAULT_DATA,
          ...data,
        },
        panelClass: data.panelClass,
      },
    );

    return {
      componentInstance: dialogRef.componentInstance,
      afterClosed$: dialogRef.afterClosed(),
    };
  }

  public openMessage(data: DialogData = {}) {
    return this.open({
      ...DIALOG_MESSAGE_DEFAULT_DATA,
      ...data,
      panelClass: data.panelClass,
    });
  }

  public openMessageInformation(data: DialogData = {}) {
    return this.openMessage({
      ...DIALOG_INFORMATION_DEFAULT_DATA,
      ...data,
      panelClass: data.panelClass,
    });
  }

  public openMessageWarning(data: DialogData = {}) {
    return this.openMessage({
      ...DIALOG_WARNING_DEFAULT_DATA,
      ...data,
      panelClass: data.panelClass,
    });
  }

  public openMessageError(data: DialogData = {}) {
    return this.openMessage({
      ...DIALOG_ERROR_DEFAULT_DATA,
      ...data,
      panelClass: data.panelClass,
    });
  }

  public openMultiMessageError(data: DialogData[]) {
    return this.openMessage({
      messages: data.map((d) => {
        return {
          ...DIALOG_ERROR_DEFAULT_DATA,
          ...d,
        };
      }),
    });
  }
}
