import { Injectable } from '@angular/core';

import { SortType } from '@enums/sort-type.enum';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  public static getSortLabel(sortType: SortType): string {
    switch (sortType) {
      case SortType.alphabet:
        return 'alphabetically';
      case SortType.code:
        return 'by code';
      case SortType.date:
        return 'by date';
      case SortType.status:
        return 'by status';
      default:
        return '';
    }
  }

  constructor() {}

  /** Sort by number (ascending) */
  public sort(a: number, b: number): number {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
  }

  /** Sort by number (descending) */
  public sortDescending(a: number, b: number) {
    if (a < b) return 1;
    else if (a > b) return -1;
    else return 0;
  }

  /** Sort alphabateically (ascending) */
  public sortAlphabetically(a: string, b: string): number {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  /** Sort by date (ascending) */
  public sortDateAscending(a: Date, b: Date): number {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
  }

  /** Sort by date (descending) */
  public sortDateDescending(a: Date, b: Date): number {
    if (a < b) return 1;
    else if (a > b) return -1;
    else return 0;
  }
}
