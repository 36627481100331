import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { of, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DialogComponent } from '@components/dialog/dialog.component';

import { ApolloHttpParamEncoder } from '@models/apollo-http-param-encoder';

import { environment } from '@environments/environment';

import { ModelMapperService } from '@services/utility/model-mapper.service';

//used to pass in optional parameters for our Interceptors by settings HttpContextTokens
export class ApiContextOptions {
  excludePracticeID?: boolean = false;
  selectedProviderID?: number | nil = null;
}

@Injectable({
  providedIn: 'root',
})
export class ApiAccessService implements OnDestroy {
  private encoder: HttpParameterCodec = new ApolloHttpParamEncoder();
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private modelMapperService: ModelMapperService,
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** Creates 'GET' request. Conditionally caches result, and/or maps result to defined type */
  public get(
    controller: string,
    method: string,
    id?: string | nil,
    cache: boolean = false,
    type?: any,
    context?: ApiContextOptions | nil,
  ): any {
    let observable: Observable<any> = this.http
      .get(`${environment.ApolloAPI}/${controller}/${method}${id != null ? '/' + id : ''}`, {
        context: this.buildContext(context),
      })
      .pipe(map((result: any) => this.mapResult(result, type)));

    if (cache) {
      let key: string = controller + method + (id != null ? id : '');
      let timeoutKey: string = key + 'Timeout';
      let timeoutItemJson: string | nil = localStorage.getItem(timeoutKey);
      let timeoutItem: number = timeoutItemJson ? JSON.parse(timeoutItemJson) : 0;
      let timeNowMS: number = Date.now();

      // If the timeout exists and has not timed out.
      if (timeoutItem && timeoutItem > timeNowMS) {
        return of(JSON.parse(localStorage.getItem(key) ?? '')).pipe(map((result: any) => this.mapResult(result, type)));
      } else {
        this.subscription.add(
          observable.subscribe((result) => {
            // Set the new data and a timeout of 24 hours.
            localStorage.setItem(timeoutKey, JSON.stringify(timeNowMS + 86400000));
            localStorage.setItem(key, JSON.stringify(result));
            return of(result);
          }),
        );

        return observable;
      }
    } else {
      return observable;
    }
  }

  /** Creates 'GET' request with parameters. Conditionally maps result to defined type */
  public getWithParams(
    controller: string,
    method: string,
    params: Map<string, string>,
    type?: any,
    context?: ApiContextOptions | nil,
  ): Observable<any> {
    let paramList: HttpParams = new HttpParams({ encoder: this.encoder });

    for (let entry of Array.from(params.entries())) paramList = paramList.set(entry[0], entry[1]);

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .get(environment.ApolloAPI + '/' + controller + '/' + method, {
        headers: headers,
        params: paramList,
        context: this.buildContext(context),
      })
      .pipe(map((result: any) => this.mapResult(result, type)));
  }

  /** Prompts user with file selection dialog, then creates 'GET' request for file */
  public getFile(
    fileDisplayName: string,
    controller: string,
    method: string,
    params: Map<string, string>,
    onConfirmation?: (confirmed: boolean) => void,
    context?: ApiContextOptions | nil,
  ): any {
    return this.downloadFileDialog(
      fileDisplayName,
      (value: boolean) => {
        if (value) {
          let paramList = new HttpParams({ encoder: this.encoder });

          for (const entry of Array.from(params.entries())) {
            paramList = paramList.set(entry[0], entry[1]);
          }

          return this.http.get(`${environment.ApolloAPI}/${controller}/${method}`, {
            responseType: 'arraybuffer',
            params: paramList,
            context: this.buildContext(context),
          });
        } else {
          return of(false);
        }
      },
      onConfirmation,
    );
  }

  /** Creates 'GET' request for 'Blob' data */
  public getBlob(controller: string, controllerMethod: string, id: string): Observable<Blob> {
    return this.http.get(`${environment.ApolloAPI}/${controller}/${controllerMethod}/${id}`, { responseType: 'blob' });
  }

  /** Creates 'POST' request. Conditionally maps result to defined type */
  public post(
    controller: string,
    method: string,
    params: Map<string, string>,
    type?: any,
    context?: ApiContextOptions | nil,
  ) {
    let body = new HttpParams({ encoder: this.encoder });
    for (let entry of Array.from(params.entries())) body = body.set(entry[0], entry[1]);

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http
      .post(environment.ApolloAPI + '/' + controller + '/' + method, body, {
        headers: headers,
        context: this.buildContext(context),
      })
      .pipe(map((result: any) => this.mapResult(result, type)));
  }

  /** Creates 'POST' request with 'Content-Type' set to 'application/json'. Conditionally maps result to defined type */
  public postJSON(controller: string, method: string, data: any, type?: any, context?: ApiContextOptions | nil) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(environment.ApolloAPI + '/' + controller + '/' + method, JSON.stringify(data), {
        headers: headers,
        context: this.buildContext(context),
      })
      .pipe(map((result: any) => this.mapResult(result, type)));
  }

  /** Creates 'POST' request for file. Conditionally maps result to defined type */
  public postFile(controller: string, method: string, fileToUpload: File, params: Map<string, string>, type?: any) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    let body = new HttpParams({ encoder: this.encoder });
    for (let entry of Array.from(params.entries())) body = body.set(entry[0], entry[1]);

    return this.http
      .post(environment.ApolloAPI + '/' + controller + '/' + method, formData, { params: body })
      .pipe(map((result: any) => this.mapResult(result, type)));
  }

  /** Creates 'PUT' request for blob */
  public putBlob(controller: string, controllerMethod: string, blob: Blob, id: string): Observable<Blob> {
    const formData: FormData = new FormData();

    formData.append('blob', blob);

    return this.http.put(`${environment.ApolloAPI}/${controller}/${controllerMethod}/${id}`, formData, {
      responseType: 'blob',
    });
  }

  /** Creates 'PUT' request */
  public put(controller: string, method: string, params: Map<string, string>) {
    let body = new HttpParams({ encoder: this.encoder });
    for (let entry of Array.from(params.entries())) body = body.set(entry[0], entry[1]);

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.put(environment.ApolloAPI + '/' + controller + '/' + method, body, { headers: headers });
  }

  /** Creates 'DELETE' request */
  public delete(controller: string, method: string, id: string, context?: ApiContextOptions | nil): Observable<any> {
    if (method) {
      return this.http.delete(`${environment.ApolloAPI}/${controller}/${method}/${id}`, {
        context: this.buildContext(context),
      });
    } else {
      return this.http.delete(`${environment.ApolloAPI}/${controller}/${id}`, { context: this.buildContext(context) });
    }
  }

  /** Prompts user with dialog with file selection control */
  private downloadFileDialog(
    fileDisplayName: string,
    onSwitchMap: (value: boolean) => Observable<any>,
    onConfirmation?: (confirmed: boolean) => void,
  ): Observable<any> {
    const dialogRef: MatDialogRef<DialogComponent, any> = this.dialog.open(DialogComponent, {
      data: {
        title: 'Download',
        message: `${fileDisplayName} will be saved to the browser's download location`,
        confirmationButtonText: 'Confirm',
      },
    });

    return dialogRef.afterClosed().pipe(
      map((value) => value == 'ok'),
      tap((x) => {
        if (onConfirmation) {
          onConfirmation(x);
        }
      }),
      switchMap((value: boolean) => onSwitchMap(value)),
    );
  }

  /** Map anonymous object to defined type */
  private mapResult(result: any, type: any): any {
    if (type) {
      return this.modelMapperService.mapResult(result, type);
    } else {
      return result;
    }
  }

  /** Conditionally set 'HttpContext' values */
  private buildContext(context: ApiContextOptions | nil): HttpContext | undefined {
    if (!context) return undefined;

    let ctx: HttpContext = new HttpContext();
    // if (context.excludePracticeID) {
    //   ctx.set(EXCLUDE_PRACTICE, true);
    // }
    // if (context.selectedProviderID) {
    //   ctx.set(PROVIDER_ID, context.selectedProviderID);
    // }
    return ctx;
  }
}
