export enum UserPermissionEnum {
  //client permissions
  practiceSettings = 1,
  providerSettings = 2,
  facilitySettings = 3,
  referringPhysicianSettings = 4,
  users = 5,
  roles = 6,
  codes = 7,
  userPracticeAccess = 8,
  audit = 9,
  patientAccount = 10,
  providerAssociation = 11,
  educationalMaterialSettings = 12,
  prescribing = 13,

  //sti console permissions
  dashboard = 101,
  consoleReporting = 102,
  clientManagement = 103,
  shardManagement = 104,
  consoleUsers = 105,
  cmmsApi = 106,
  consoleCodes = 107,
  terms = 108,
  consoleAudit = 109,
  systemStatus = 110,
  helpManagement = 111
}

export enum UserPermissionLevelEnum {
  none = 0,
  view = 2,
  write = 4,
  full = 8,
}
