import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { UserPermissionLevel } from '../modules/core/models/user/user-role.model';

import { ClientPracticeView } from '@models/client/client.model';

import { ApiAccessService } from '@services/api-access.service';

@Injectable({
  providedIn: 'root',
})
export class SessionContextService {
  private readonly clientsSubject: BehaviorSubject<ClientPracticeView[]> = new BehaviorSubject<ClientPracticeView[]>(
    [],
  );
  public readonly clients$: Observable<ClientPracticeView[]> = this.clientsSubject.asObservable();
  private readonly sessionInfoLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly sessionInfoLoaded$: Observable<boolean> = this.sessionInfoLoaded.asObservable();

  //security
  public accessToken: string = '';
  public permissions: UserPermissionLevel[] = [];
  public securityToken: string = '';

  constructor(private apiAccessService: ApiAccessService) {}

  public get clients(): ClientPracticeView[] {
    return this.clientsSubject.getValue();
  }

  public set clients(value: ClientPracticeView[]) {
    this.clientsSubject.next(value);
  }

  public getAvailableClientList(): Observable<ClientPracticeView[]> {
    return this.apiAccessService.get('client', 'GetClientListForUser', null, false, ClientPracticeView).pipe(
      tap((results: ClientPracticeView[]) => {
        if (results?.length) {
          this.clients = results;
        }
      }),
    );
  }
}
