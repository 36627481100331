@if (data.icon) {
    <app-icon [icon]="data.icon" />
}
@if (!data.messages) {
    <div mat-dialog-content>
        @if (!data.messages) {
            <ng-container
                [ngTemplateOutlet]="dialogMessage"
                [ngTemplateOutletContext]="{ data: data }"></ng-container>
        }
    </div>
    @if (showConfirmationInput) {
        <div
            mat-dialog-content
            class="confirmation-input">
            <div>{{ data.confirmationInputLabel }}</div>
            <mat-form-field
                class="input-container"
                subscriptSizing="dynamic">
                <input
                    class="clearable-input"
                    matInput
                    type="text"
                    placeholder="{{ data.confirmationInputPlaceholder }}"
                    (input)="inputChange($event)" />
            </mat-form-field>
        </div>
    }
} @else {
    <div mat-dialog-content>
        <mat-tab-group>
            @for (message of data.messages; track message) {
                <mat-tab
                    [label]="message.title ?? ''"
                    class="truncate">
                    <div class="error-tab-content">
                        <ng-container
                            [ngTemplateOutlet]="dialogMessage"
                            [ngTemplateOutletContext]="{ data: message }"></ng-container>
                    </div>
                </mat-tab>
            }
        </mat-tab-group>
    </div>
}
@if (!data.hideCancel || !data.hideConfirm || data.additionalButtons?.length) {
    <div
        class="button-container"
        mat-dialog-actions>
        @if (!data.hideCancel) {
            <button
                mat-stroked-button
                color="primary"
                [mat-dialog-close]="'cancel'"
                cdkFocusInitial>
                {{ data.cancelButtonText ? data.cancelButtonText.toUpperCase() : "CANCEL" }}
            </button>
        }
        @for (button of data.additionalButtons; track button) {
            <button
                mat-stroked-button
                color="primary"
                [mat-dialog-close]="button.dialogCloseResult">
                {{ button.label.toUpperCase() }}
            </button>
        }
        @if (!data.hideConfirm) {
            <button
                [disabled]="!enableConfirmationButton"
                mat-flat-button
                color="primary"
                [mat-dialog-close]="'ok'">
                {{ data.confirmationButtonText ? data.confirmationButtonText.toUpperCase() : "OK" }}
            </button>
        }
    </div>
}

<ng-template
    #dialogMessage
    let-data="data">
    <span [innerHtml]="data.message"></span>
    @if (data.items && data.items.length) {
        <div>
            <ul>
                @for (item of data.items; track item) {
                    <li>{{ item }}</li>
                }
            </ul>
        </div>
    }
</ng-template>
