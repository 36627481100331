import { Injectable } from '@angular/core';

@Injectable()
export class EqualityService {
  constructor() {}

  /**
   * Determines equality of two objects by iterating over objects' keys
   *
   * a and b are the objects to compare.
   * excludedKeys are the string names of the keys (properties) that are to be ignored during the comparison
   *
   */
  public isEqual(a: any, b: any, excludedKeys?: any[]): boolean {
    if ((!a && !b) || a === b) {
      return true;
    } else if ((!a && b) || (a && !b)) {
      return false;
    }

    if (typeof a == typeof b) {
      if (typeof a == 'object') {
        let aIsDate: boolean = a instanceof Date;
        let bIsDate: boolean = b instanceof Date;

        if (Object.keys(a)?.length != Object.keys(b)?.length) {
          return false;
        } else if (
          (aIsDate && !bIsDate) ||
          (!aIsDate && bIsDate) ||
          (aIsDate && bIsDate && (a as Date).getTime() != (b as Date).getTime())
        ) {
          return false;
        } else {
          for (let i: number = 0; i < Object.keys(a).length; i++) {
            let key: any = Object.keys(a)[i];
            if (excludedKeys == null || !excludedKeys?.includes(key)) {
              if (!Object.keys(b).includes(key)) {
                return false;
              }

              if (typeof (a[key] == 'object')) {
                if (!this.isEqual(a[key], b[key], excludedKeys)) {
                  return false;
                }
              } else {
                if (a[key] != b[key]) {
                  return false;
                }
              }
            }
          }
        }
      } else {
        if (a != b) {
          return false;
        }
      }
    } else {
      return false;
    }

    return true;
  }

  /**
   * getPropertyName
   *
   * This returns the string value of the property name that is passed in for 'propertyValue'.
   *
   * For example, to get a string representation of Person's 'dateOfBirth' property,
   * you'd call 'getPropertyName(this.person, this.person.dateOfBirth)' and that would return the string 'dateOfBirth'.
   *
   * This is useful for getting property names (keys) to pass in to the 'excludedKeys' parameter of the isEqual method above instead of hard-coding the strings,
   * since over time those property names could change.
   */
  public getPropertyName(sourceObject: any, propertyValue: any): string {
    for (var i in sourceObject) {
      if (sourceObject[i] == propertyValue) {
        return i;
      }
    }
    return '';
  }
}
